import axios from "@/plugins/axios-ext";
import promiseHandlers from "@/services/promise-handlers";

class UserService {
  login(user) {
    return axios
      .post('https://api.leisureking.eu/v3l/userauthenticate', {
        username: user.username,
        password: user.password,
        source: 'fd.leisureking.eu'
      })
      .then(response => {
        if (response && response.data && response.data.status && response.data.status === "OK") {
          user.password = ''
          user.username = response.data.data.token.name
          user.token = response.data.data.token.jwt
          user.language = 'nl'
          user.hashcode = response.data.data.token.hashcode
          user.email = response.data.data.token.email
          user.fdAppVersion = response.data.data.token.fdAppVersion
          return user
        }
      });
  }
  checkUnlockCode(code) {
    return new Promise((resolve, reject) => {
      axios
        .post("https://api.leisureking.eu/v3l/users/checkunlockcode", {
          unlockcode: code,
        })
        .then((res) => promiseHandlers.pThen(res, resolve, reject))
        .catch((res) => promiseHandlers.pCatch(res, resolve, reject));
    });
  }
  getShops() {
    return new Promise((resolve, reject) => {
      axios.post('https://api.leisureking.eu/v3l/users/getshops', {})
        .then(res => promiseHandlers.pThen(res, resolve, reject))
        .catch(res => promiseHandlers.pCatch(res, resolve, reject))
    })
  }
  getPreferences() {
    return new Promise((resolve, reject) => {
      axios.post('https://api.leisureking.eu/v3l/users/getpreferences',{})
        .then(res => promiseHandlers.pThen(res, resolve, reject))
        .catch(res => promiseHandlers.pCatch(res, resolve, reject))
    })
  }
  getPreference(optionKey) {
    return new Promise((resolve, reject) => {
      axios.post('https://api.leisureking.eu/v3l/users/getpreference',{preference: optionKey})
        .then(res => promiseHandlers.pThen(res, resolve, reject))
        .catch(res => promiseHandlers.pCatch(res, resolve, reject))
    })
  }
  setPreference(optionKey, value) {
    return new Promise((resolve, reject) => {
      axios.post('https://api.leisureking.eu/v3l/users/setpreference',{preference: optionKey, value: value})
        .then(res => promiseHandlers.pThen(res, resolve, reject))
        .catch(res => promiseHandlers.pCatch(res, resolve, reject))
    })
  }
  getNewJwt(shophid) {
    return new Promise((resolve, reject) => {
      axios.post('https://api.leisureking.eu/v3l/users/getnewjwt',{shophid})
          .then(res => promiseHandlers.pThen(res, resolve, reject))
          .catch(res => promiseHandlers.pCatch(res, resolve, reject))
    })
  }
}

export default new UserService();
