import axios from '@/plugins/axios-ext'
import promiseHandlers from "@/services/promise-handlers";

class ScanAndGoService {
  async scan(shophid, code) {
    if(code.includes('https://leis.io/qr/')){
      code = code.substring(19);
    }

    if(code.substring(0, 2) === "=S") {
      code = code.substring(2, 6);
      let ret = await axios.post('https://api.leisureking.eu/v3l/Reservations/fromShortCode', {shortcode: code})
                  .then(res => {return res})
                  .catch(err => {return err})

      code = "=H" + ret.data.data.reshash + ";";
    }

    return await new Promise((resolve, reject) => {
       axios.post('https://api.leisureking.eu/v3l/ScanAndGo/scan',{shophid: shophid, code: code})
         .then(res => promiseHandlers.pThen(res, resolve, reject))
         .catch(res => promiseHandlers.pCatch(res, resolve, reject))
    })
  }
  hasOptionMaterial(shophid) {
    return new Promise((resolve, reject) => {
      axios.post('https://api.leisureking.eu/v3l/ScanAndGo/hasOptionMaterial',{shophid: shophid})
        .then(res => promiseHandlers.pThen(res, resolve, reject))
        .catch(res => promiseHandlers.pCatch(res, resolve, reject))
    })
  }
  getMaterial(shophid, idmaterial){
    return new Promise((resolve, reject) => {
      axios.post('https://api.leisureking.eu/v3l/ScanAndGo/get',{shophid: shophid, idmaterial: idmaterial})
        .then(res => promiseHandlers.pThen(res, resolve, reject))
        .catch(res => promiseHandlers.pCatch(res, resolve, reject))
    })
  }
  listByReservation(shophid, reshid){
    return new Promise((resolve, reject) => {
      axios.post('https://api.leisureking.eu/v3l/ScanAndGo/listbyreservation',{shophid: shophid, reshid: reshid})
        .then(res => promiseHandlers.pThen(res, resolve, reject))
        .catch(res => promiseHandlers.pCatch(res, resolve, reject))
    })
  }
  setToReservation(shophid, reshid, idmaterial){
    return new Promise((resolve, reject) => {
      axios.post('https://api.leisureking.eu/v3l/ScanAndGo/settoreservation',{shophid: shophid, reshid: reshid, idmaterial: idmaterial})
        .then(res => promiseHandlers.pThen(res, resolve, reject))
        .catch(res => promiseHandlers.pCatch(res, resolve, reject))
    })
  }
  unsetFromReservation(shophid, idmaterial){
    return new Promise((resolve, reject) => {
      axios.post('https://api.leisureking.eu/v3l/ScanAndGo/unsetfromreservation',{shophid: shophid, idmaterial: idmaterial})
        .then(res => promiseHandlers.pThen(res, resolve, reject))
        .catch(res => promiseHandlers.pCatch(res, resolve, reject))
    })
  }
  toggleOutOfUse(shophid, idmaterial, value, note){
    return new Promise((resolve, reject) => {
      axios.post('https://api.leisureking.eu/v3l/ScanAndGo/toggleOutOfUse',{shophid: shophid, outoforder: value, idmaterial: idmaterial, note:note})
        .then(res => promiseHandlers.pThen(res, resolve, reject))
        .catch(res => promiseHandlers.pCatch(res, resolve, reject))
    })
  }
  changeNote(shophid, idmaterial, note){
    return new Promise((resolve, reject) => {
      axios.post('https://api.leisureking.eu/v3l/ScanAndGo/changeNote',{shophid: shophid, note: note, idmaterial: idmaterial})
        .then(res => promiseHandlers.pThen(res, resolve, reject))
        .catch(res => promiseHandlers.pCatch(res, resolve, reject))
    })
  }
  updateStatus(shophid, idmaterial, status){
    return new Promise((resolve, reject) => {
      axios.post('https://api.leisureking.eu/v3l/ScanAndGo/updateStatus',{shophid, status, idmaterial})
        .then(res => promiseHandlers.pThen(res, resolve, reject))
        .catch(res => promiseHandlers.pCatch(res, resolve, reject))
    })
  }
  addRepair(shophid, idmaterial, description){
    return new Promise((resolve, reject) => {
      axios.post('https://api.leisureking.eu/v3l/ScanAndGo/addRepair',{shophid, description, idmaterial})
        .then(res => promiseHandlers.pThen(res, resolve, reject))
        .catch(res => promiseHandlers.pCatch(res, resolve, reject))
    })
  }
  getRepairPresets(shophid){
    return new Promise((resolve, reject) => {
      axios.post('https://api.leisureking.eu/v3l/ScanAndGo/getRepairPresets',{shophid})
        .then(res => promiseHandlers.pThen(res, resolve, reject))
        .catch(res => promiseHandlers.pCatch(res, resolve, reject))
    })
  }

}

export default new ScanAndGoService();
