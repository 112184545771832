import Vue from 'vue'

const toast = {
  methods: {
    toast: (type, msg, title) => {
      const vm = new Vue()
      const options = {title: title, variant: 'success', autoHideDelay: 4000}
      if (type === 'err') {
        options.title = title ? title : 'Foutmelding!'
        options.variant = 'danger'
      }
      if (type === 'notice') {
        options.title = title ? title : 'Let op!'
        options.variant = 'warning'
      }
      if (msg !== 'redirect') {
        vm.$bvToast.toast(msg, options)
      }
    }
  }
}
export default toast
