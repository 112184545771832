import ShopService from "@/services/shops.service";
import AddOnsService from "@/services/addons.service";
import store from "@/store/index";
import {router} from "@/router";

export const bus = {
  namespaced: true,
  state: {
    currentShop: null,
    currentResHID: null,
    showScan: false,
    scrollToTop: false,
    borgRefund: false
  },
  getters: {
    currentShop: state => state.currentShop,
    currentResHID: state => state.currentResHID,
    showScan: state => state.showScan,
    showScrollToTop: state => state.scrollToTop,
    borgRefund: state => state.borgRefund
  },
  actions: {
    setDefaultShop() {
      this.user = store.getters["user/currentUser"]
      if (!this.user.shops) {
        return Promise.resolve()
      }
      let s = this.user.shops[0]
      try {
        if (this.user.preferences.preferenceShop) {
          let ds = this.user.shops.filter(s => s.hashcode === this.user.preferences.preferenceShop)
          if (ds && ds.length === 1) {
            s = ds[0]
          }
        }
        if (this.user.preferences.defaultShop) {
          let ds = this.user.shops.filter(s => s.hashcode === this.user.preferences.defaultShop)
          if (ds && ds.length === 1) {
            s = ds[0]
          }
        }
      } finally {
        store.dispatch("bus/changeShop", s).then(() => {
          return Promise.resolve()
        })
      }
    },
    async changeShop({commit, dispatch}, shop) {
      if (localStorage) {
        localStorage.setItem(`lk.currentShop`, JSON.stringify({vestiging: shop.vestiging, hashcode: shop.hashcode}))
      }
      document.title = `${shop.vestiging} - LeisureKing Front Desk`;
      await dispatch("user/getNewToken", shop.hashcode, {root: true});
      dispatch("validateRights");
      ShopService.getOptions(shop.hashcode)
        .then((d) => {
          shop.options = d
        })
        .catch(() => {
          shop.options = {}
        })
        .finally(() => {
          ShopService.getValuta(shop.hashcode)
            .then((d) => {
              shop.valuta = d
            })
            .catch(() => {
              shop.valuta = {}
            }).finally(() => {
            AddOnsService.getIntergrations(shop.hashcode)
              .then((d) => {
                shop.addons = d
              })
              .catch(() => {
                shop.valuta = {}
              }).finally(() => {
              commit('changeShop', shop)
              return Promise.resolve()
            })
          })
        })
    },
    validateRights({rootGetters}) {
      const requiredRights = router.currentRoute.meta["requiredRights"];
      if (!requiredRights)
        return; // No rights required, continue

      const hasRights = rootGetters["user/hasRights"](requiredRights);
      if (!hasRights) // Check if user has rights for current route
        router.push("/"); // Required rights not available, redirect to '/'
    },
    changeRes({commit}, resHID) {
      commit('changeRes', resHID)
      if (resHID === null && localStorage) {
        localStorage.setItem(`lk.shortcode`, null);
      }
      return Promise.resolve()
    },
    openScan({commit}) {
      commit('openScan')
      return Promise.resolve()
    },
    closeScan({commit}) {
      commit('closeScan')
      return Promise.resolve()
    },
    setBorgRefund({commit}, amount) {
      commit('setBorgRefund', amount)
      return Promise.resolve()
    },
    unsetBorgRefund({commit}) {
      commit('unsetBorgRefund')
      return Promise.resolve()
    },
  },
  mutations: {
    changeShop(state, shop) {
      state.currentShop = shop
    },
    changeRes(state, resHID) {
      state.currentResHID = resHID
    },
    openScan(state) {
      state.showScan = true
    },
    closeScan(state) {
      state.showScan = false
    },
    setBorgRefund(state, amount) {
      state.borgRefund = amount
    },
    unsetBorgRefund(state) {
      state.borgRefund = false
    },
  }
};
