<template>
  <b-container fluid class="mt-2"
               v-if="
      this.$store.getters['bus/currentShop'] !== null &&
      this.currentUser !== null
    "
  >
    <b-row>
      <b-col cols="12" md="6" xl="4" class="p-3">
        <b-card :title="$t('instellingen.voorkeuren')" class="mb-2">
          <b-list-group flush>
            <b-list-group-item>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <span class="font-weight-bold">{{ $t("alg.taal") }}</span>
                </b-input-group-prepend>
                <div class="w-100 d-sm-none"></div>
                <b-form-input
                    disabled
                    :value="$i18n.locale === 'nl' ? 'Nederlands' : 'English'"
                ></b-form-input>
                <b-input-group-append>
                  <b-dropdown :text="$t('alg.kies')" variant="info">
                    <b-dropdown-item @click="$i18n.locale = 'nl'"
                    >Nederlands
                    </b-dropdown-item
                    >
                    <b-dropdown-item @click="$i18n.locale = 'en'"
                    >English
                    </b-dropdown-item
                    >
                  </b-dropdown>
                </b-input-group-append>
              </b-input-group>
            </b-list-group-item>
            <b-list-group-item>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <span class="font-weight-bold">{{
                      $t("instellingen.standaardvestiging")
                    }}</span>
                </b-input-group-prepend>
                <div class="w-100 d-sm-none"></div>
                <b-form-input disabled :value="currentPrefShop"></b-form-input>
                <b-input-group-append>
                  <b-dropdown :text="$t('alg.kies')" variant="info">
                    <b-dropdown-item
                        v-for="shop in currentUser.shops"
                        v-bind:key="shop.FKid_vestiging"
                        @click="
                        setApiUserPreference('defaultShop', shop.hashcode)
                      "
                    >
                      {{ shop.vestiging }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-input-group-append>
              </b-input-group>
            </b-list-group-item>
            <b-list-group-item>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <span class="font-weight-bold">Status</span>
                </b-input-group-prepend>
                <div class="w-100 d-sm-none"></div>
                <b-form-input disabled></b-form-input>
                <b-input-group-append>
                  <b-dropdown
                      right
                      id="filterStatus"
                      :text="$t('alg.kies')"
                      variant="primary"
                  >
                    <b-form-checkbox-group
                        class="px-3 py-2"
                        switches
                        v-model="selectedReservationStatus"
                    >
                      <b-form-checkbox
                          v-for="status in reservationStatus"
                          v-bind:key="status.value"
                          :value="status.value"
                      >{{ status.text | capitalize }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-dropdown>
                </b-input-group-append>
              </b-input-group>
            </b-list-group-item>

            <b-list-group-item>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <span class="font-weight-bold">{{
                      $t("instellingen.darkmode")
                    }}</span>
                </b-input-group-prepend>
                <div class="w-100 d-sm-none"></div>
                <b-form-input disabled></b-form-input>
                <b-input-group-append is-text>
                  <b-form-checkbox
                      switch
                      class="mr-n2"
                      :checked="darkmode"
                      @change="
                      $store.commit('misc/toggleDarkMode');
                    "
                  >
                  </b-form-checkbox>
                </b-input-group-append>
              </b-input-group>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" xl="4" class="p-3">
        <b-card :title="$t('instellingen.apparaten')" class="mb-2">
          <b-list-group flush>
            <b-list-group-item>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <div class="font-weight-bold">
                    {{ $t("instellingen.pinautomaat") }}
                  </div>
                </b-input-group-prepend>
                <div class="w-100 d-sm-none"></div>
                <b-form-input readonly :value="defaultEFT.desc"></b-form-input>
                <b-input-group-append>
                  <b-dropdown
                      :text="$t('alg.kies')"
                      variant="info"
                      v-model="defaultEFT"
                      :disabled="possibleEFTs.length === 0"
                  >
                    <b-dropdown-item
                        v-for="pm in possibleEFTs"
                        v-bind:key="pm.id_terminal"
                        @click="setLocalStoragePreference('defaultEFT', pm)"
                    >
                      {{ pm.desc }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-input-group-append>
              </b-input-group>
            </b-list-group-item>
            <b-list-group-item>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <span class="font-weight-bold">Scan Device</span>
                </b-input-group-prepend>
                <div class="w-100 d-sm-none"></div>
                <b-form-input disabled :value="scanDevice"></b-form-input>
                <b-input-group-append>
                  <b-dropdown
                      :text="$t('alg.kies')"
                      variant="info"
                      v-model="scanDevice"
                  >
                    <b-dropdown-item
                        @click="setLocalStoragePreference('scanDevice', 'Camera')"
                    >Camera
                    </b-dropdown-item
                    >
                    <b-dropdown-item
                        @click="
                        setLocalStoragePreference('scanDevice', 'TextScanner')
                      "
                    >TextScanner
                    </b-dropdown-item>
                  </b-dropdown>
                </b-input-group-append>
              </b-input-group>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" xl="4" class="p-3">
        <b-card :title="$t('instellingen.display')" class="mb-2">
          <b-list-group flush>
            <b-list-group-item>
              <b-input-group v-if="customerDisplayBgColor !== null">
                <b-input-group-prepend is-text>
                  <span class="font-weight-bold">{{
                      $t("instellingen.textkleur")
                    }}</span>
                </b-input-group-prepend>
                <div class="w-100 d-sm-none"></div>
                <b-form-input
                    disabled
                    :style="{ 'background-color': customerDisplayTextColor }"
                    :value="customerDisplayTextColor"
                ></b-form-input>
                <b-input-group-append is-text>
                  <verte
                      picker="square"
                      :showHistory="null"
                      model="rgb"
                      v-model="customerDisplayTextColor"
                      @input="
                      setLocalStoragePreference(
                        'customerDisplay.textColor',
                        customerDisplayTextColor
                      )
                    "
                  ></verte>
                </b-input-group-append>
              </b-input-group>
            </b-list-group-item>
            <b-list-group-item>
              <b-input-group v-if="customerDisplayBgColor !== null">
                <b-input-group-prepend is-text>
                  <span class="font-weight-bold">{{
                      $t("instellingen.achtergrond")
                    }}</span>
                </b-input-group-prepend>
                <div class="w-100 d-sm-none"></div>
                <b-form-input
                    disabled
                    :style="{ 'background-color': customerDisplayBgColor }"
                    :value="customerDisplayBgColor"
                ></b-form-input>
                <b-input-group-append is-text>
                  <verte
                      picker="square"
                      :showHistory="null"
                      model="rgb"
                      v-model="customerDisplayBgColor"
                      @input="
                      setLocalStoragePreference(
                        'customerDisplay.bgColor',
                        customerDisplayBgColor
                      )
                    "
                  ></verte>
                </b-input-group-append>
              </b-input-group>
            </b-list-group-item>
            <b-list-group-item>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <span class="font-weight-bold">{{
                      $t("instellingen.logo")
                    }}</span>
                </b-input-group-prepend>
                <div class="w-100 d-sm-none"></div>
                <b-form-input disabled></b-form-input>
                <b-input-group-append is-text>
                  <b-form-checkbox
                      switch
                      class="mr-n2"
                      v-model="customerDisplayLogo"
                      @change="
                      setLocalStoragePreference(
                        'customerDisplay.logo',
                        customerDisplayLogo
                      )
                    "
                  >
                  </b-form-checkbox>
                </b-input-group-append>
              </b-input-group>
            </b-list-group-item>
            <b-list-group-item>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <span class="font-weight-bold">{{
                      $t("instellingen.reviews")
                    }}</span>
                </b-input-group-prepend>
                <div class="w-100 d-sm-none"></div>
                <b-form-input disabled></b-form-input>
                <b-input-group-append is-text>
                  <b-form-checkbox
                      switch
                      class="mr-n2"
                      v-model="customerDisplayReviews"
                      @change="
                      setLocalStoragePreference(
                        'customerDisplay.reviews',
                        customerDisplayReviews
                      )
                    "
                  >
                  </b-form-checkbox>
                </b-input-group-append>
              </b-input-group>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <a href="/display/display.html" target="_blank">Open Display</a>
              <small>{{ $t("instellingen.klik") }}</small>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>

    </b-row>
  </b-container>
</template>

<script>
import LayoutDefault from "@/layouts/LayoutDefault";

import ShopsService from "@/services/shops.service";
import UserService from "@/services/user.service";

import Verte from "verte";
import "verte/dist/verte.css";

import {validationMixin} from "vuelidate";

import {
  required,
  minLength,
} from "vuelidate/lib/validators";

const moment = require("moment");

export default {
  name: "Settings",
  mixins: [validationMixin],
  components: {Verte},

  validations: {
    user: {
      firstName: {
        required,
        minLength: minLength(2),
      },
      birthdate: {
        required,
        maxValue: (value) => {
          return moment(value, "YYYY-MM-DD").isBefore(
              moment().subtract(18, "years")
          );
        },
      },
    },
  },
  data() {
    return {
      unwatchShop: null,
      user: {},
      defaultEFT: "",
      possibleEFTs: [],
      scanDevice: "Camera",
      currentPrefShop: "None",
      customerDisplayBgColor: null,
      customerDisplayTextColor: null,
      customerDisplayLogo: true,
      customerDisplayReviews: true,
      isSaving: false,
      selectedReservationStatus: [],
      reservationStatus: [
        {value: 1, text: this.$t("status.aangevraagd")},
        {value: 2, text: this.$t("status.bezigmetreservering")},
        {value: 3, text: this.$t("status.gereserveerd")},
        {value: 4, text: this.$t("status.actief")},
        {value: 5, text: this.$t("status.wachtopbetaling")},
        {value: 6, text: this.$t("status.betalingscontrole")},
        {value: 7, text: this.$t("status.uitgesteldafgehandeld")},
        {value: 9, text: this.$t("status.afgehandeld")},
        {value: 10, text: this.$t("status.geannuleerd")},
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters["user/currentUser"];
    },
    darkmode() {
      return this.$store.getters["misc/getDarkMode"];
    }
  },
  methods: {
    getCurrentPrefShop() {
      const cu = this.$store.getters["user/currentUser"];
      if (cu !== null) {
        let c = cu.shops.filter(
            (x) => x.hashcode === cu.preferences.defaultShop
        );
        if (c.length === 1) {
          this.currentPrefShop = c[0].vestiging;
        } else {
          this.currentPrefShop = "None";
        }
      }
    },
    validateState(name) {
      const {$dirty, $error} = this.$v.user[name];
      return $dirty ? !$error : null;
    },
    saveProfile() {
      this.isSaving = true;
      this.$v.user.$touch();
      if (this.$v.user.$anyError) {
        return;
      }
      if (this.user !== null) {
        this.$scrollTo("#top", 1, {offset: -70});

        this.$store
            .dispatch("account/setUserData", this.user)
            .then((u) => {
              this.initUserModel(u);
              this.isSaving = false;
              this.$bvToast.toast(`De wijzigingen zijn opgeslagen!`, {
                title: "Wijzigingen opgeslagen",
                variant: "success",
                autoHideDelay: 4000,
              });
              if (this.$store.getters["bus/firstTimer"]) {
                this.$bvModal.show("profileouttro");
              }
            })
            .catch((err) => {
              this.isSaving = false;
              this.$bvToast.toast(`De wijzigingen zijn niet opgeslagen! ${err}`, {
                title: "Foutmelding",
                variant: "danger",
                autoHideDelay: 4000,
              });
            });
      }
    },
    async getEFTs() {
      try {
        const currentshop = this.$store.getters["bus/currentShop"];
        if (currentshop !== null) {
          const paymentMethods = await ShopsService.getPaymentMethods(
              currentshop.hashcode
          );
          for (const pm in paymentMethods) {
            if (paymentMethods[pm].id_payment_method === 2) {
              this.possibleEFTs = paymentMethods[pm].terminals;
              this.possibleEFTs.push({id_terminal: "none", desc: "--NONE--"});
            }
          }
        }
      } catch (e) {
        this.toast("err", e.toString());
      }
    },
    async setApiUserPreference(key, value) {
      try {
        await UserService.setPreference(key, value);
        if (key === "defaultShop") {
          this.currentPrefShop = "Saved!";
        }
      } catch (e) {
        this.toast("err", e.toString());
      }
    },
    setLocalStoragePreference(key, value) {
      this[key] = value;
      localStorage.setItem(`lk.${key}`, JSON.stringify(value));
      if (key === "customerDisplay.logo" && value === true) {
        ShopsService.getLogo(
            this.$store.getters["bus/currentShop"].hashcode
        ).then((data) => {
          this.setLocalStoragePreference("customerDisplay.logodata", data);
        });
      }
    },
    init() {
      this.getEFTs();
      this.getCurrentPrefShop();
      this.defaultEFT = JSON.parse(localStorage.getItem("lk.defaultEFT")) || "";
      this.scanDevice =
          JSON.parse(localStorage.getItem("lk.scanDevice")) || "Camera";
      this.customerDisplayBgColor =
          JSON.parse(localStorage.getItem("lk.customerDisplay.bgColor")) ||
          "#000000";
      this.customerDisplayTextColor =
          JSON.parse(localStorage.getItem("lk.customerDisplay.textColor")) ||
          "#000000";
      this.customerDisplayLogo =
          JSON.parse(localStorage.getItem("lk.customerDisplay.logo")) || false;
      this.customerDisplayReviews =
          JSON.parse(localStorage.getItem("lk.customerDisplay.reviews")) || false;
      this.selectedReservationStatus =
          JSON.parse(localStorage.getItem("lk.preferences.statusPreset")) || [];
    },
  },
  watch: {
    selectedReservationStatus: function () {
      if (
          this.$store.getters["bus/currentShop"] !== null &&
          this.$store.getters["user/currentUser"] !== null
      ) {
        this.setApiUserPreference(
            "selectedReservationStatus",
            this.selectedReservationStatus
        );
        this.setLocalStoragePreference(
            "preferences.statusPreset",
            this.selectedReservationStatus
        );
      }
    },
  },
  mounted() {
    if (this.$store.getters["bus/currentShop"] !== null) {
      this.init();
    }
  },
  beforeMount() {
  },
  beforeDestroy() {
    this.unwatchShop();
  },
  created() {
    this.$emit(`update:layout`, LayoutDefault);
    this.unwatchShop = this.$store.watch(
        (state, getters) => getters["bus/currentShop"],
        () => {
          if (
              this.$store.getters["bus/currentShop"] !== null &&
              this.$store.getters["user/currentUser"] !== null
          ) {
            this.init();
          }
        }
    );
  },
};
</script>

<style lang="scss" scoped>
.bootstrap-select.form-control.input-group-btn {
  z-index: auto;
}
</style>
