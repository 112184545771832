import UserService from "@/services/user.service";
import {router} from "@/router";

export const user = {
    namespaced: true,
    state: {loggedIn: false, locked: false, user: null},
    getters: {
        jwt: (state) => state.user.token,
        loggedIn: (state) => state.loggedIn,
        currentUser: (state) => state.user,
        rights: (state, getters) => {
            const token = getters.jwt
                .split(".")[1]
                .replace("-", "+")
                .replace("_", "/");

            const decodedToken = JSON.parse(
                decodeURIComponent(
                    Array.prototype.map
                        .call(
                            atob(token),
                            (c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                        )
                        .join("")
                )
            );
            return decodedToken["rights"];
        },
        hasRight: (state, getters) => (right) => {
            try {
                return getters.rights.includes(right);
            } catch (e) {
                return [];
            }
        },
        hasRights: (state, getters) => (rights) => {
            let hasRights = true;
            rights.forEach((right) => {
                if (!getters.hasRight(right)) {
                    hasRights = false;
                }
            });
            return hasRights;
        },
    },
    actions: {
        async login(context, user) {
            const data = await UserService.login(user);
            context.commit("loginSuccess", data);
            try {
                user.shops = await UserService.getShops();
            } catch (err) {
                user.shops = [];
            } finally {
                context.commit("getShops", user);
            }
            try {
                user.preferences = await UserService.getPreferences();
                if (localStorage) {
                    localStorage.setItem(
                        `lk.preferences.statusPreset`,
                        JSON.stringify(user.preferences.selectedReservationStatus || [])
                    );
                }
            } catch (err) {
                user.preferences = [];
            } finally {
                context.commit("getPreferences", user);
            }
        },
        logout({commit}) {
            //hoeft niet via de service, want de backend hoeft niks van de uitlog te weten
            commit("logout");
            if (router.currentRoute.path !== "/login") {
                router.push("/login");
            }
        },
        async getNewToken({commit}, shophid) {
            const {token} = await UserService.getNewJwt(shophid);
            commit("setToken", token);
        },
        lock({commit}) {
            commit("lock");
            router.push("/lock");
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.loggedIn = false;
            state.user = null;
        },
        getShops(state, user) {
            state.user = user;
        },
        getPreferences(state, user) {
            state.user = user;
        },
        logout(state) {
            state.loggedIn = false;
            state.locked = false;
            state.user = null;
            sessionStorage.clear();
        },
        lock(state) {
            state.locked = true;
        },
        unlock(state) {
            state.locked = false;
        },
        setToken(state, token) {
            state.user.token = token;
        }
    },
};
