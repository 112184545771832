import AssortimentService from "@/services/assortiment.service";
export const misc = {
  namespaced: true,
  state: {
    assortimentsData: [],
    lastInteractionDT: Date.now(),
    darkmode:false
  },
  getters: {
    getAssortimentsData: (state) => state.assortimentsData,
    getDarkMode: (state) => state.darkmode,
    getLockTimeout: () => {
      return 1000 * 60 * 3; // milliseconds * seconds * minutes
    },
  },
  actions: {
    getAssortimentsData({ commit }) {
      return AssortimentService.getAllShops()
        .then((d) => {
          commit("getAssortimentsDataSuccess", d);
          return Promise.resolve();
        })
        .catch((err) => {
          commit("getAssortimentsDataFailure");
          return Promise.reject(err);
        });
    },
  },
  mutations: {
    getAssortimentsDataSuccess(state, items) {
      state.assortimentsData = items;
    },
    getAssortimentsDataFailure(state) {
      state.assortimentsData = null;
    },
    updateLastInteractionDT(state) {
      state.lastInteractionDT = Date.now();
    },
    toggleDarkMode(state){
      state.darkmode = !state.darkmode;
    }
  },
};
