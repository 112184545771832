<template>
  <div>
  <div id="login" class="h-100" v-if="!loggedIn">
    <b-card title="" header-tag="header" footer-tag="footer" bg-variant="light" header-bg-variant="dark"
            border-variant="info"
            footer-bg-variant="dark"
            class="mx-auto"
    >
      <template v-slot:header>
        <div>
          <img alt="LeisurekingLogo"
              src="@/assets/images/leisure-king-logo.png"
              class="img-card"
          />
        </div>
      </template>
      <b-form @submit.prevent="handleLogin">
        <b-card-text class="text-dark">{{ $t('login.loginmetlk') }}</b-card-text>
        <b-form-group
            id="fieldset-1"
            :label="$t('alg.gebruikersnaam')"
            label-for="username"
            label-class="text-primary"
            :invalid-feedback="invalidFeedbackUser"
            :state="state1"
        >
          <b-form-input id="username" autocomplete="username" v-model="user.username" :state="state1" trim></b-form-input>
        </b-form-group>
        <b-form-group
            id="fieldset-2"
            :label="$t('alg.wachtwoord')"
            label-for="password"
            label-class="text-primary"
            :invalid-feedback="invalidFeedbackPassword"
            :state="state2"
        >
          <b-form-input id="password" type="password" autocomplete="current-password" v-model="user.password" :state="state2" trim></b-form-input>
        </b-form-group>
        <b-form-group class="w-100 pt-3">
          <b-button class="w-100" :disabled="loading || !state1 || !state2"  type="submit">
            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
            <span>Inloggen</span>
          </b-button>
        </b-form-group>
        <b-form-group class="w-100 pt-3">
          <b-dropdown block split variant="primary" menu-class="w-100">
            <template slot="button-content">
              <div class="d-flex">
                <img alt="locale" class="img-fluid" width="40" :src="require(`@/assets/images/countries/${$i18n.locale}.svg`)">
                <span class="pl-4">{{$i18n.locale === 'nl' ? 'Nederlands' : 'English'}}</span>
              </div>
            </template>
            <b-dropdown-item @click.prevent="$i18n.locale='nl'">
              <div class="d-flex">
                <img  alt="nl" class="img-fluid p-2" width="40" src="@/assets/images/countries/nl.svg" />
                <span class="pl-4">Nederlands</span>
              </div>
            </b-dropdown-item>
            <b-dropdown-item @click.prevent="$i18n.locale='en'">
              <div class="d-flex">
                <img alt="en" class="img-fluid p-2" width="40" src="@/assets/images/countries/en.svg" />
                <span class="pl-4">English</span>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </b-form-group>
      </b-form>

      <div class="form-group">
        <div v-if="message" class="alert alert-danger" role="alert">{{ message }}</div>
      </div>

      <template v-slot:footer>
        <div class="row no-gutters">
          <div class="col-6 text-center">
            <small><a class="text-primary"
                      href="https://www.leisureking.nl/nl/faq">Veel gestelde vragen</a></small>
          </div>
          <div class="col-6 text-center">
            <small><a class="text-primary"
                      href="https://www.leisureking.nl/nl">Wat is Leisureking?</a></small>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-12 text-center">
            <small class="text-muted">{{appVersion}}</small>
          </div>
        </div>
      </template>
    </b-card>
  </div>

    <div v-if="loggedIn" class="w-100 d-flex align-items-center flex-column">
      <div>Succesfully Logged In!</div>
      <template v-if="!hasRights">
        {{ $t("login.norights") }}
        <button class="btn btn-secondary" @click="signout">
          {{ $t("nav.logoff") }}
        </button>
      </template>
      <div v-else>
        <router-link to="reservations">Go to Reservations</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import User from '../models/user';
import LayoutLogin from "@/layouts/LayoutLogin";
import UserService from "@/services/user.service";

export default {
  name: 'Login',
  data() {
    return {
      user: new User('', '', ''),
      loading: false,
      message: '',
      appVersion: this.$appVersion
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters["user/loggedIn"];
    },
    state1() {
      return this.user.username.length >= 4
    },
    state2() {
      return this.user.password.length >= 5
    },
    invalidFeedbackUser() {
      if (this.user.username.length === 0) {
        return ''
      } else if (this.user.username.length < 4) {
        return 'Uw gebruikersnaam bestaat uit minimaal 4 karakters'
      } else {
        return ''
      }
    },
    invalidFeedbackPassword() {
      if (this.user.password.length === 0) {
        return ''
      } else if (this.user.password.length < 5) {
        return 'Uw wachtwoord bestaat uit minimaal 5 karakters'
      } else {
        return ''
      }
    },
    hasRights() {
      return this.$store.getters["user/hasRights"]([
        "customer_read",
        "reservation_read",
        "shop_read",
      ]);
    },
  },
  methods: {
    async handleLogin() {
      this.loading = true;
      if (!this.state1 || !this.state2) {
        this.loading = false;
        return;
      }
      if (this.user.username && this.user.password) {
        try {
          await this.$store.dispatch('user/login', this.user)
          await this.$store.dispatch('bus/setDefaultShop')
          await this.$store.dispatch("misc/getAssortimentsData")
          await this.$store.commit("misc/updateLastInteractionDT");
          await UserService.setPreference('fdAppVersion', this.$appVersion)
          //foutmeldingen bij inlog voorkomen
            setTimeout(()=>{
              this.$router.push('/reservations')
            },300)
        } catch (error) {
          let message =
              (error.response && error.response.data) ?
                  error.response.data.message :
                  error.toString();
          if(message === 'Unauthorized'){
            message = 'Ongeldige inloggegevens!'
          }
          this.message = message
        } finally {
          this.loading = false;
        }
      }
    },
    signout() {
      this.$store.dispatch("user/logout");
    },
  },
  created() {
    this.$emit(`update:layout`, LayoutLogin);
    document.title = 'LeisureKing Front Desk'
    if (localStorage) {
      localStorage.setItem(`vuex-multi-tab`, '')
    }
  },
  mounted() {
    if (this.loggedIn)
      this.$store.dispatch("user/logout");
  }
}
</script>

<style scoped lang="scss">
#login {
  .card {
    max-width: 21rem;

    .card-header {
      img {
        display: block;
        margin: 0 auto 10px;
        width: 50px;
      }
    }
  }
}
@include media-breakpoint-up(md) {
  #login {
    .card {
      .card-header {
        img {
          width: 150px;
        }
      }
    }
  }
}


</style>
