<template>
  <div id="reservation_overview" class="position-relative" v-if="this.$store.getters['bus/currentShop'] !== null">
    <!-- overlay isloading -->
    <b-overlay :show="isLoading" :no-center="true" no-wrap>
      <template v-slot:overlay>
        <div class="h-100" style="background-color: rgba(0,0,0,0.3)">
          <div class="w-50 mx-auto">
            <div class="text-center p-4 bg-primary text-light rounded">
              <b-icon icon="three-dots" animation="cylon" font-scale="4" variant="white"></b-icon>
              <div class="mb-3 text-white">Loading...</div>
            </div>
          </div>
        </div>
      </template>
    </b-overlay>
    <QRScannerModal return-focus="#reservations_list" :return-function="onScannerClosed"></QRScannerModal>
    <!-- Start reserveringenlijst -->
    <div id="reservations_list" :class="{'hasRes' : currentRes}">
      <!-- Start zoekbalk -->
      <div class="container-fluid overview_wrapper pt-2 pt-md-0">
        <div class="overflow-hidden overview_header rounded no-gutters my-2">
          <b-row class="no-gutters">
            <b-col cols="10">
              <b-row class="">
                <b-col cols="12" class="">
                  <date-picker
                      v-model="dates"
                      :format="'DD-MM-YYYY'"
                      :range-separator="' / '"
                      :lang="{
                          formatLocale: {
                            firstDayOfWeek: 1,
                          }
                        }"
                      range
                      :placeholder="$t('reslijst.datumpickerplaceholder')"
                      value-type="YYYY-MM-DD"
                      :editable="false"
                      ref="datePicker"
                      class="w-100"
                      input-class="form-control border-0 rounded-0"
                      popup-class="distanceTopPopup rounded"
                      v-b-tooltip.hover.bottom :title="$t('tooltip.kalender')"
                  >
                    <template v-slot:header="{ emit }">
                      <b-button-group class="flex-wrap w-100 p-2">
                        <b-button variant="outline-primary" squared size="sm" @click="datePickerToday(emit)">
                          {{ $t('reslijst.datumpickervandaag') }}
                        </b-button>
                        <b-button variant="outline-primary" squared size="sm" @click="datePickerTomorrow(emit)">
                          {{ $t('reslijst.datumpickermorgen') }}
                        </b-button>
                        <b-button variant="outline-primary" squared size="sm" @click="datePickerYesterday(emit)">
                          {{ $t('reslijst.datumpickergisteren') }}
                        </b-button>
                        <b-button variant="outline-primary" squared size="sm" @click="datePickerNextWeek(emit)">
                          {{ $t('reslijst.datumpickervolgendeweek') }}
                        </b-button>
                        <b-button variant="outline-primary" squared size="sm" @click="datePickerLastWeek(emit)">
                          {{ $t('reslijst.datumpickervorigeweek') }}
                        </b-button>
                      </b-button-group>
                    </template>
                    <template v-slot:footer>
                      <b-button variant="primary" size="sm" @click="$refs.datePicker.closePopup()">
                        <b-icon icon="x"></b-icon>
                      </b-button>
                    </template>
                  </date-picker>
                </b-col>
                <b-col cols="12" class="">
                  <b-button-group class="w-100 text-center">
                    <b-button
                        class="w-100 rounded-0 border-right"
                        v-b-tooltip.hover.bottom :title="$t('tooltip.reserveringsstatus')"
                        @click="showStatusForm = !showStatusForm; timePickerOpen ? timePickerOpen = false : ''"
                    >
                      <b-icon scale="0.6" v-show="showStatusForm" icon="chevron-up"></b-icon>
                      <b-icon scale="0.6" v-show="!showStatusForm" icon="chevron-down"></b-icon>
                      Status
                    </b-button>
                    <date-picker
                        v-model="selectedTime"
                        type="time"
                        format="HH:mm"
                        :minute-step="15"
                        value-type="format"
                        :scroll-duration="0"
                        class="w-100 border-right"
                        :editable="false"
                        ref="timePicker"
                        :open="timePickerOpen"
                        :clearable="false"
                        v-b-tooltip.hover.bottom :title="$t('tooltip.starttijd')"
                    >
                      <template v-slot:input>
                        <b-button class="rounded-0 w-100"
                                  @click="timePickerOpen = !timePickerOpen; showStatusForm ?  showStatusForm = false : ''">
                          <b-icon icon="clock"></b-icon>
                        </b-button>
                      </template>
                      <template v-slot:icon-calendar><span></span></template>
                    </date-picker>
                  </b-button-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="2" class="">
              <b-button
                  @click="removeAllFilters()"
                  squared
                  class="w-100 h-100"
                  v-b-tooltip.hover.bottom :title="$t('tooltip.verwijderfilters')"
              >
                <b-icon icon="x-square"></b-icon>
              </b-button>
            </b-col>
            <transition name="slide">
              <b-col cols="12" v-if="showStatusForm" class="bg-white">
                <b-form class="px-3 py-2">
                  <b-form-checkbox-group v-model="selectedReservationStatus" switches>
                    <b-form-checkbox
                        v-for="status in reservationStatus"
                        v-bind:key="status.value"
                        :value="status.value"
                        class="w-100"
                    >{{ status.text | capitalize }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form>
              </b-col>
            </transition>
          </b-row>
        </div>


        <b-row>
          <b-col cols="12" class="mb-1">
            <b-form-group class="mb-2">
              <b-input-group>
                <b-form-input
                    id="filter"
                    v-model="filterValue"
                    v-on:input="filtering"
                    type="search"
                    :placeholder="$t('reslijst.filterplaceholder')"
                >
                </b-form-input>
                <b-input-group-append v-b-tooltip.hover.bottom :title="$t('tooltip.qrcode')">
                  <b-button
                      @click="toggleScan()"
                      :pressed="showScan() ? true : null"
                      class="w-100"
                  >
                    <b-icon icon="upc-scan"></b-icon>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-button
                variant="primary"
                v-if="this.advancedSearch === true && filterValue.length > 3"
                v-on:click="findReservation()"
                class="btn-block"
            >{{ $t('reslijst.uitgebreidzoeken') }}
            </b-button>
          </b-col>
        </b-row>
        <!-- Einde zoekbalk -->
      <b-row>
        <b-col cols="12" class="mb-2">
          <div class="w-100 px-3 text-center text-black-50" v-if="this.rawApiReservations">
            <b-progress height="3px" :value="this.rows" :max="this.rawApiReservations.length" ></b-progress>
            <div class="d-flex justify-content-between">
              <div class="small">
                <div v-if="loadingReservations">
                  <div class="spinner-border spinner-border-sm"></div>
                </div>
                <div v-else>
                  <template v-if="loadingReservations">{{ this.rawApiReservations.length }}</template>
                  <template v-if="!loadingReservations">{{ this.filteredList.length }}</template>
                  {{ $t('alg.van') }} {{ this.rows }} {{ $t('reslijst.reserveringen') }}
                  <span v-if="this.filteredList.length !== this.rows && !loadingReservations">({{
                      $t('alg.gefilterd')
                    }})</span>
                </div>
              </div>
              <div class="small" v-show="reservationsLastRetrieved !== ''"  v-b-tooltip.hover.bottom :title="$t('tooltip.reslistrefresh')">
                {{ $t('alg.laatsteOpgehaald') }} <span class="font-weight-bold">{{$moment(reservationsLastRetrieved, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}}</span>

              </div>
            </div>
          </div>
        </b-col>
      </b-row>
        <!-- Start reserveringenlijst -->
        <b-row>
          <div class="w-100">
            <div
                v-for="res in filteredList"
                v-bind:key="res.internalkey"
                :class="currentRes && currentRes.internalkey === res.internalkey ? 'bg-white' : ''"
                class="w-100 reservationItem p-3 border-bottom"
            >
              <div
                  class="reservationStatus"
                  :class="'status_bg_'+res.booking_status.status_id"
              ></div>

              <b-row>
                <b-col cols="8" v-on:click="$route.params.hid === res.hash ?  selRes(null) :  selRes(res.hash)"
                       class="text-gray pointer">
                  <div>
                    <div class="w-100 d-inline-block text-truncate resDetail">{{ res.customerdata.firstname }}
                      {{ res.customerdata.lastname }}
                    </div>
                    <div class="mt-n2">{{ res.internalkey }}</div>
                  </div>
                  <div class="mt-2">
                    <div class="small">{{ res.calculatedDate }}</div>
                    <div class="small" v-if="res.calculatedEndDateOrTime != ''">{{ res.calculatedEndDateOrTime }}</div>
                    <div class="small" :class="'text-'+res.booking_status.status_id">
                      {{ res.booking_status.content_value.nl | capitalize }}
                    </div>
                  </div>
                </b-col>
                <b-col cols="4" class="d-flex flex-column justify-content-between">
                  <div class="priceDetail w-100"
                       :class="[
                              { 'payed' : res.value.amount === res.payed.amount },
                              { 'notPayed' : res.value.amount > res.payed.amount },
                              { 'overPaid' : res.value.amount < res.payed.amount }
                              ]"
                  >
                    <span v-html="res.value.currency_sign"></span>
                    <span>{{ res.value.amount | money(res.value.currency_id) }}</span>
                  </div>
                  <div class="text-right" v-if="res.booking_remark">
                     <span class="h4" v-b-tooltip.hover :title="res.booking_remark">
                       <b-icon icon="info-square"></b-icon>
                       </span>
                  </div>
                  <b-dropdown class="w-100"
                              right
                              split-variant="lk_yellow_dark"
                              variant="lk_yellow_dark"
                              :text="$t('reslijst.acties')"
                              v-if="hasRights"
                  >
                    <b-dropdown-item-button
                        v-if="(res.booking_status.status_id > 2 && res.booking_status.status_id < 9 && res.booking_status.status_id !== 4)"
                        v-on:click="setStatus(4, res.hash)"
                    >{{ $t('reslijst.uitgeven') }}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                        v-if="(res.booking_status.status_id === 4)"
                        v-on:click="setStatus(9, res.hash)"
                    >
                      {{ $t('reslijst.binnenboeken') }}
                    </b-dropdown-item-button>
                    <!--                    <b-dropdown-item-button v-on:click="setStatus(--, res.hash)">{{$t('reslijst.uitgesteld')}}</b-dropdown-item-button>-->
                    <b-dropdown-item-button
                        v-on:click="showReservationPaymentModal(res.hash)"
                        :disabled="res.value.amount <= res.payed.amount"
                    >{{ $t('reslijst.betalen') }}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                        v-on:click="showBorgModal(res.hash)"
                    >{{ $t('reslijst.addborg') }}
                    </b-dropdown-item-button>
                  </b-dropdown>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-row>
      </div>
    </div>
    <!-- Einde reserveringenlijst -->

    <div id="reservation_details" class="pt-2">

      <!-- reserveringsdetails -->

      <div id="reservation_detail_wrapper" class="container-fluid">
        <a id="rd_top"></a>
        <template v-if="!currentRes">
          <div class="w-100 text-center p-5 bg-lk-grey">
            <h3 class="text-dark">{{ $t('reslijst.geenselectie') }}</h3>
            <template v-if="hasRights">
              <h3 class="text-dark">{{ $t('alg.of') }}</h3>
              <div class="w-100">
                <b-button to="reservationnew" id="resnewbutton"
                          class="mx-auto d-flex align-items-center justify-content-center">{{
                    $t('alg.maakreservering')
                  }}
                </b-button>
              </div>
            </template>
          </div>
        </template>
        <template v-if="currentRes && currentRes.shophid !== this.$store.getters['bus/currentShop'].hashcode">
          <div class="closeDetails mb-3 mt-2">
            <b-button @click="selRes(null)">
              <b-icon icon="arrow-left"></b-icon>
              {{ $t('alg.terug') }}
            </b-button>
          </div>
          <div class="w-100 bg-warning text-center p-5">
            <h3 class=" text-white">{{ $t('reslijst.shopnomatch') }}</h3>
          </div>
        </template>
        <template v-if="currentRes && currentRes.shophid === this.$store.getters['bus/currentShop'].hashcode">
          <div class="closeDetails mb-3 mt-2">
            <b-button @click="selRes(null)">
              <b-icon icon="arrow-left"></b-icon>
              {{ $t('alg.terug') }}
            </b-button>
          </div>
          <template>
            <b-row>
              <ReservationHeader
                  :reservation="this.currentRes"
                  @reservationsOverviewRefresh="refreshReservation()"
                  class="col-12 col-xl-8"
              >
              </ReservationHeader>
              <ReservationPayments
                  :reservation="this.currentRes"
                  @reservationsOverviewRefresh="refreshReservation()"
                  class="col-12 col-xl-4 d-flex align-items-stretch"
              >
              </ReservationPayments>
            </b-row>
            <b-row>
              <Customer
                  v-if="this.currentRes.customerdata.customerhid !== 'a64eba55b6bbd07369daf8ae62731a53dcd042d1d023f1e65f07e080a0efac90'"
                  :chid="this.currentRes.customerdata.customerhid"
                  :resid="this.currentRes.internalkey"
                  class="col-12"
              >
              </Customer>
              <ReservationDetails
                  :reservation="this.currentRes"
                  @reservationsOverviewRefresh="refreshReservation()"
                  class="col-12"
                  style=""
              >
              </ReservationDetails>
              <ReservationNotes
                  :reservation="this.currentRes"
                  :currentUser="this.currentUser"
                  class="col-12"
              >
              </ReservationNotes>
            </b-row>
          </template>
          <!-- Einde reserveringsdetails -->
          <!-- Materiaal -->
          <b-row v-if="hasScanAndGo">
            <b-col cols="12" v-if="currentRes">
              <div class="blockElm">
                <ReservationsMaterial @reservationOverviewScrollUp="scrollUp"></ReservationsMaterial>
              </div>
            </b-col>
          </b-row>
          <!-- Einde Materiaal -->
          <b-row>
            <b-col cols="12" v-if="currentRes">
              <b-button @click="scrollUp()">
                Top
              </b-button>
            </b-col>
          </b-row>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutDefault from '../layouts/LayoutDefault.vue'

import ReservationsService from '../services/reservations.service'

import Customer from '@/components/Customer'
import ReservationDetails from '@/components/ReservationDetails'
import ReservationsMaterial from '@/components/ReservationsMaterial'
import ReservationPayments from '@/components/ReservationPayments';
import ReservationNotes from '@/components/ReservationNotes';
import ReservationHeader from '@/components/ReservationHeader';


import QRScannerModal from "@/components/QRScannerModal"
import {isNavigationFailure} from "vue-router/src/util/errors";

export default {
  name: 'ReservationsOverview',
  components: {
    QRScannerModal,
    ReservationDetails,
    ReservationsMaterial,
    Customer,
    ReservationNotes,
    ReservationPayments,
    ReservationHeader
  },
  params: {
    hid: {
      type: String,
      default: null,
      required: false
    }
  },
  data() {
    return {
      reservationsLastRetrieved: "",
      startDate: new this.$moment,
      endDate: new this.$moment,
      rawApiReservations: [],
      assortmentList: [],
      currentRes: null,
      currentPage: 1,
      perPage: 30,
      rows: 0,
      loading: null,
      filteredList: [],
      filterValue: '',
      dates: [null, null],
      isLoading: false,
      loadingReservations: false,
      advancedSearch: false,
      showStatusForm: false,
      selectedReservationStatus: [],
      selectedTime: null,
      timePickerOpen: false,
      show: false,
      reservationStatus: [
        {value: 1, text: this.$t("status.aangevraagd")},
        {value: 2, text: this.$t("status.bezigmetreservering")},
        {value: 3, text: this.$t("status.gereserveerd")},
        {value: 4, text: this.$t("status.actief")},
        {value: 5, text: this.$t("status.wachtopbetaling")},
        {value: 6, text: this.$t("status.betalingscontrole")},
        {value: 7, text: this.$t("status.uitgesteldafgehandeld")},
        {value: 9, text: this.$t("status.afgehandeld")},
        {value: 10, text: this.$t("status.geannuleerd")}
      ],
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters["user/currentUser"]
    },
    hasScanAndGo() {
      return this.$store.getters["bus/currentShop"].options.materials || false
    },
    media() {
      return {
        'is-phone': this.$screen.sm,
        'is-tablet': this.$screen.md,
        'is-desktop': this.$screen.lg,
        'can-touch': this.$screen.touch,
        'breakpoint': this.$screen.breakpoint,
      };
    },
    hasRights() {
      return this.$store.getters['user/hasRight']("reservation_crud");
    }
  },
  methods: {
    scrollUp() {
      if (this.media["is-desktop"] || this.media["is-tablet"]) {
        this.$scrollTo('#rd_top', 300, {offset: -70, container: '#reservation_details'})
      } else {
        this.$scrollTo('#rd_top', 300, {offset: -70, container: '#main'})
      }

    },
    showScan() {
      return this.$store.getters["bus/showScan"]
    },
    selRes(hash) {
      if (hash === null) {
        if (this.$route.path !== '/reservations') {
          this.$router.push('/reservations').catch((failure) => {
            if (isNavigationFailure(failure)) {
              return
            }
            throw new Error(failure)
          })
        }
      } else {
        if (this.$route.path !== `/reservations/${hash}`) {
          this.$router.push(`/reservations/${hash}`).catch((failure) => {
            if (isNavigationFailure(failure)) {
              return
            }
            throw new Error(failure)
          })
        }
      }
    },
    async refreshReservation() {
      //dit stuurt een event waar child components op zouden kunnen refreshen die zelf ook data opmoeten halen
      //de andere componenten worden door de props geupdate (binding :) )
      this.$emit('refreshReservationsOverview')
      const resHID = this.$store.getters["bus/currentResHID"]
      if (!resHID) {
        this.currentRes = null;
        return;
      }
      //data opnieuw opvragen
      try {
        const res = await ReservationsService.get(resHID)
        const details = await ReservationsService.getdetails(resHID)
        this.currentRes = res

        if (details === 'not found') {
          this.currentRes.details = []
        } else {
          this.currentRes.details = details
        }

        const d = this.dateDispayCalculator(this.currentRes)
        this.currentRes.calculatedDate = d.calculatedDate
        this.currentRes.calculatedEndDateOrTime = d.calculatedEndDateOrTime

        //reslist aanpassen
        this.$set(
            this.filteredList,
            this.filteredList.findIndex(x => x.hash === resHID),
            res)
        this.$set(
            this.rawApiReservations,
            this.rawApiReservations.findIndex(x => x.hash === resHID),
            res)

      } catch (error) {
        this.toast('err', error)
      }
    },
    async setStatus(status, reservationHash) {
      try {
        await ReservationsService.setstatus(reservationHash, status)
        this.toast('', 'Status gewijzigd', 'Gelukt')
        this.rawApiReservations.find(x => x.hash === reservationHash).booking_status.status_id = status
      } catch (err) {
        this.toast('err', err)
      }
    },
    showReservationPaymentModal(reservationHash) {
      if (this.currentRes && this.currentRes.hash && this.currentRes.hash !== reservationHash) {
        this.selRes(reservationHash)
      }
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'paymentModal')
      }, 500)
    },
    showBorgModal(reservationHash) {
      if (this.currentRes && this.currentRes.hash && this.currentRes.hash !== reservationHash) {
        this.selRes(reservationHash)
      }
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'modal-borg')
      }, 500)
    },
    toggleScan() {
      if (!this.showScan) {
        this.selRes(null)
        this.$store.dispatch("bus/closeScan")
      }
      this.$store.dispatch("bus/openScan")
    },
    onScannerClosed() {
      this.$emit('refreshMaterials')
    },

    async getList(force) {
      if(force === undefined){
        force = false;
      }
      if (this.dates[0] == null && this.dates[1] == null) {
        let formatDate = new this.$moment
        this.filteredList = []
        this.rows = 0
        this.selRes(null)
        this.dates[0] = formatDate.format('YYYY-MM-DD')
        this.dates[1] = formatDate.format('YYYY-MM-DD')
      }

      let start = this.dates[0]
      let end = this.dates[1]
      let startfrom = 0

      try {
        let data = {};
        if (force === false) {
          //alleen nieuwe res ophalen
          const newReservations = await ReservationsService.listNew(this.$store.getters["bus/currentShop"].hashcode, startfrom, this.reservationsLastRetrieved);
          if (newReservations !== "not found") {
            const totalRecordsSum = this.pagination["total-records"] + newReservations.pagination["total-records"];
            const endRecordSum = this.pagination["end-record"] + newReservations.pagination["end-record"];

            data = {
              pagination: {
                "total-records": totalRecordsSum,
                "start-record": this.pagination["start-record"],
                "end-record": endRecordSum > totalRecordsSum ? this.pagination["end-record"] : endRecordSum,
                "records-per-page": newReservations.pagination["records-per-page"]
              },
              "server-timestamp": newReservations["server-timestamp"]
            };
            data.reservations =  [
              ...this.rawApiReservations
            ]

            newReservations.reservations.forEach((item)=>{
              const idx = data.reservations.findIndex(x => x.hash === item.hash)
              if(idx > -1){
                //al in de lijst
                data.reservations[idx] = item
              }else{
                data.reservations.push(item)
              }
            })

            this.rawApiReservations = data.reservations
            this.pagination = data.pagination
            this.rows = data.pagination['total-records']
            if (data["server-timestamp"]) {
              this.reservationsLastRetrieved = data["server-timestamp"]
            }
            const startRecord = data.pagination['start-record']
            const endRecord = data.pagination['end-record']
            setTimeout(() => {
              this.dateDisplayChanger(startRecord, endRecord)
              this.filtering()
            }, 100)
          } else {
            return;
          }
        } else {
          //verse lijst halen
          this.rawApiReservations = [];
          data = await ReservationsService.list(this.$store.getters["bus/currentShop"].hashcode, start, end, startfrom)
          if (data === 'not found') {
            this.rawApiReservations = []
            this.pagination = {}
            this.pagination['start-record'] = 0
            this.pagination['end-record'] = 0
            this.rows = 0
            setTimeout(() => {
              this.dateDisplayChanger(0, 0)
              this.filtering()
            }, 100)
          }else{
            this.rawApiReservations = data.reservations
            this.pagination = data.pagination
            this.rows = data.pagination['total-records']
            if (data["server-timestamp"])
              this.reservationsLastRetrieved = data["server-timestamp"];

            const startRecord = data.pagination['start-record']
            const endRecord = data.pagination['end-record']
            setTimeout(() => {
              this.dateDisplayChanger(startRecord, endRecord)
              this.filtering()
            }, 100)

            //paginereing
            while (startfrom < this.rows && this.rows > 29) {
              startfrom += 30
              this.loadingReservations = true
              let extraData = await ReservationsService.list(this.$store.getters["bus/currentShop"].hashcode, start, end, startfrom)
              const startRecord = extraData.pagination['start-record']
              const endRecord = extraData.pagination['end-record']
              setTimeout(() => {
                this.dateDisplayChanger(startRecord, endRecord)
              }, 100)
              this.rawApiReservations = this.rawApiReservations.concat(extraData.reservations)
              if (extraData.reservations.length < 30) {
                this.dateDisplayChanger(startRecord, endRecord)
                this.filtering()
                this.loadingReservations = false
                return
              }
            }
          }
        }
      } catch (err) {
        this.rawApiReservations = []
        this.toast('err', 'getList ' + err)
        console.log(err.stack)
      }
      if(this.getListRefreshInterval) {
        clearTimeout(this.getListRefreshInterval);
      }
      this.getListRefreshInterval = setTimeout(this.getList, 30000);
    },
    filtering() {
      if (this.rawApiReservations.length === 0) {
        return
      }
      let filteredList = this.rawApiReservations

      if (this.selectedReservationStatus.length > 0) {
        const statusFilter = event => this.selectedReservationStatus.includes(event.booking_status.status_id)
        filteredList = filteredList.filter(statusFilter)
      }

      if (this.selectedTime !== null) {
        const timeFilter = event => event.dt_start.includes(this.selectedTime)
        filteredList = filteredList.filter(timeFilter)
      }

      const filterValue = this.filterValue

      if (filterValue === null || filterValue === '') {
        this.filteredList = filteredList
        return
      }

      const filter = event =>
          String(event.internalkey).includes(filterValue) ||
          String(event.shortcode).includes(filterValue) ||
          String(event.customerdata.firstname).toLowerCase().includes(filterValue.toLowerCase()) ||
          String(event.customerdata.lastname).toLowerCase().includes(filterValue.toLowerCase()) ||
          String(event.customerdata.fullsaluation).toLowerCase().includes(filterValue.toLowerCase()) ||
          String(event.booking_status.content_value.nl).toLowerCase().includes(filterValue.toLowerCase()) ||
          String(event.booking_status.content_value.en).toLowerCase().includes(filterValue.toLowerCase())

      if (filteredList.filter(filter).length > 0) {
        this.filteredList = filteredList.filter(filter)
        this.advancedSearch = false
      } else {
        this.filteredList = []
        this.advancedSearch = true
      }
    },
    removeAllFilters() {
      this.selectedReservationStatus = JSON.parse(localStorage.getItem('lk.preferences.statusPreset')) || []
      this.showStatusForm = false
      this.timePickerOpen = false
      this.selectedTime = null
      this.filterValue = ''
      this.dates = [null, null]
      this.filteredList = []
      this.rows = 0
      this.selRes(null)
    },
    dateDisplayChanger(startRecord, endRecord) {
      for (let i = startRecord; i < (endRecord + 1); i++) {
        if(this.rawApiReservations[i] === undefined){
          return;
        }
        const d = this.dateDispayCalculator(this.rawApiReservations[i])
        this.rawApiReservations[i].calculatedDate = d.calculatedDate
        this.rawApiReservations[i].calculatedEndDateOrTime = d.calculatedEndDateOrTime
      }
    },
    dateDispayCalculator(res) {
      const today = new this.$moment
      const tomorrow = new this.$moment(((new this.$moment()).valueOf() + 1000 * 3600 * 24))
      const yesterday = new this.$moment(((new this.$moment()).valueOf() - 1000 * 3600 * 24))

      if (!res.dt_start || !res.dt_end) {
        return {calculatedDate: 'unknown', calculatedEndDateOrTime: 'unknown'}
      }
      let calculatedDate = ''
      let calculatedEndDateOrTime = ''
      let sameDate = 0
      let startDate = res.dt_start.substring(0, 10).split("-").reverse().join("-")
      let startDateString = res.dt_start.substring(0, 10).split("-").reverse().join("-")
      let endDate = ''
      let endDateString = ''
      if (res.dt_end) {
        endDate = res.dt_end.substring(0, 10).split("-").reverse().join("-")
        endDateString = res.dt_end.substring(0, 10).split("-").reverse().join("-")
      }

      if (startDate === endDate) {
        sameDate = 1
      }

      if (startDate === today.format('DD-MM-YYYY')) {
        startDateString = this.$t('reslijst.datumpickervandaag')
      }
      if (startDate === tomorrow.format('DD-MM-YYYY')) {
        startDateString = this.$t('reslijst.datumpickermorgen')
      }
      if (startDate === yesterday.format('DD-MM-YYYY')) {
        startDateString = this.$t('reslijst.datumpickergisteren')
      }
      if (endDate === today.format('DD-MM-YYYY')) {
        endDateString = this.$t('reslijst.datumpickervandaag')
      }
      if (endDate === tomorrow.format('DD-MM-YYYY')) {
        endDateString = this.$t('reslijst.datumpickermorgen')
      }
      if (endDate === yesterday.format('DD-MM-YYYY')) {
        endDateString = this.$t('reslijst.datumpickergisteren')
      }


      if (sameDate === 1) {
        calculatedDate = startDateString
        calculatedEndDateOrTime = res.dt_start.substring(11, 16) + " - " + res.dt_end.substring(11, 16)
      } else if (endDate === '') {
        calculatedDate = startDateString
        calculatedEndDateOrTime = res.dt_start.substring(11, 16)
      } else {
        calculatedDate = startDateString + " " + res.dt_start.substring(11, 16)
        calculatedEndDateOrTime = endDateString + " " + res.dt_end.substring(11, 16)
      }
      return {calculatedDate, calculatedEndDateOrTime}
    },
    findReservation() {
      this.loadingReservations = true
      let searchstring = this.filterValue.toLowerCase()

      if (this.filterValue.length > 3) {
        this.rows = 0
        ReservationsService.find(this.$store.getters["bus/currentShop"].hashcode, searchstring)
            .then(
                data => {
                  this.rawApiReservations = data.searchresult
                  this.rows = this.rawApiReservations.length
                  this.dateDisplayChanger(0, this.rawApiReservations.length)
                  this.filtering()
                  this.loadingReservations = false
                })
            .catch(
                err => {
                  this.rawApiReservations = []
                  this.loadingReservations = false
                  this.toast('err', err)
                })
      }
    },
    getAssortiment() {
      try {
        this.assortmentList = this.$store.getters["misc/getAssortimentsData"][this.$store.getters["bus/currentShop"].hashcode]
      } catch (e) {
        this.toast('err', 'getAssortiment failed' + e.toString())
      }
    },
    datePickerToday(emit) {
      const start = new Date();
      const end = new Date();
      const date = [start, end];
      emit(date);
      this.$refs.datePicker.closePopup()
    },
    datePickerTomorrow(emit) {
      const start = new Date();
      start.setTime(start.getTime() + 1 * 24 * 3600 * 1000);
      const date = [start, start];
      emit(date);
      this.$refs.datePicker.closePopup()
    },
    datePickerYesterday(emit) {
      const start = new Date();
      start.setTime(start.getTime() - 1 * 24 * 3600 * 1000);
      const date = [start, start];
      emit(date);
      this.$refs.datePicker.closePopup()
    },
    datePickerNextWeek(emit) {
      const start = new Date();
      const end = new Date();
      end.setTime(end.getTime() + 7 * 24 * 3600 * 1000);
      const date = [start, end];
      emit(date);
      this.$refs.datePicker.closePopup()
    },
    datePickerLastWeek(emit) {
      const start = new Date();
      const end = new Date();
      start.setTime(start.getTime() - 7 * 24 * 3600 * 1000);
      const date = [start, end];
      emit(date);
      this.$refs.datePicker.closePopup()
    },
    init() {
      this.getAssortiment()
      this.getList(true)
      if (this.$route.params.hid !== undefined && this.$route.params.hid !== null) {
        this.selRes(this.$route.params.hid)
      }
    }
  },
  watch: {
    dates: function () {
      this.filteredList = []
      this.rows = 0
      this.selRes(null)
      this.getList(true)
    },
    currentPage: function () {
      this.getList(true)
    },
    selectedReservationStatus: function () {
      this.filtering()
    },
    selectedTime: function () {
      this.filtering()
    },
  },
  created() {
    this.$emit(`update:layout`, LayoutDefault);
    this.unwatchShop = this.$store.watch(
        (state, getters) => getters["bus/currentShop"],
        () => {
          this.filteredList = []
          this.rows = 0
          this.init()
        },
    );
    this.unwatchRes = this.$store.watch(
        (state, getters) => getters["bus/currentResHID"],
        async (resHID) => {
          this.isLoading = true
          if (resHID === null) {
            if (localStorage) {
              localStorage.setItem(`lk.shortcode`, null);
            }
            this.currentRes = null
            this.isLoading = false
            return
          } else {
            let foundRes = this.rawApiReservations.find(x => x.hash === resHID)
            if (foundRes === undefined) {
              try {
                foundRes = await ReservationsService.get(resHID)
                this.rawApiReservations = []
                this.rawApiReservations.push(foundRes)
                this.rows = 1
                this.perPage = 1
                this.filtering()
              } catch (e) {
                if (e.toString() === 'Not Found') {
                  this.toast('notice', this.$t('reslijst.notfound'))
                } else {
                  this.toast('err', e.toString())
                }
                this.selRes(null)
                return
              }
            }
            try {
              const details = await ReservationsService.getdetails(resHID)
              this.currentRes = foundRes
              if (details === 'not found') {
                this.currentRes.details = []
              } else {
                this.currentRes.details = details
              }
              if (localStorage && this.currentRes.shortcode) {
                localStorage.setItem(`lk.shortcode`, this.currentRes.shortcode);
              }
            } catch (error) {
              this.toast('err', error)
              this.selRes(null)
              return
            }
            if (this.showScan()) {
              await this.$store.dispatch("bus/closeScan")
            }
            setTimeout(() => {
              this.$emit('refreshReservationsOverview')
            }, 100)
            setTimeout(() => {
              this.isLoading = false
              this.scrollUp()
            }, 300)
          }
        },
    );
  },
  mounted() {
    if (this.$store.getters["bus/currentShop"] !== null) {
      this.init()
    }
    this.selectedReservationStatus = JSON.parse(localStorage.getItem('lk.preferences.statusPreset')) || []
  },
  beforeDestroy() {
    this.$store.dispatch("bus/changeRes", null)
    if (localStorage) {
      localStorage.setItem(`lk.shortcode`, null);
    }
    if (this.getListRefreshInterval) {
      clearInterval(this.getListRefreshInterval);
    }
    this.unwatchShop();
    this.unwatchRes();
  },
  async beforeRouteUpdate(to, from, next) {
    if (to.params.hid && to.params.hid !== null) {
      await this.$store.dispatch("bus/changeRes", to.params.hid)
    } else {
      await this.$store.dispatch("bus/changeRes", null)
    }
    next()
  }
}
</script>

<style lang="scss">
.slide-enter-active {
  transition-duration: 0.4s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.slide-enter-to, .slide-leave {
  max-height: 300px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.hasRes {
  display: none;
}

.closeDetails {
  display: block;
}

.resDetail {
  font-size: 20px;
}

.text-gray {
  color: $lk_grey_8;
}

.priceDetail {
  border: 1px solid $lk_grey;
  border-radius: 3px;
  display: block;
  text-align: center;
}

.payed {
  border-color: $lk_green;
  color: $lk_green;
}

.notPayed {
  border-color: $lk_red;
  color: $lk_red;
}

.overPaid {
  border-color: $lk_orange;
  color: $lk_orange;
}
.pointer {
  cursor: pointer;
}

#resnewbutton {
  height: $tile-height-sm;
  max-width: 22em;
}

@media(max-width: 750px) {
  .mx-calendar {
    width: 100% !important;
  }
  .distanceTopPopup {
    left: 0px !important;
  }
}

@media(max-height: 793px) {
  .distanceTopPopup {
    top: 114px !important;
  }
}

#reservation_overview {
  display: block;
  height: 100%;

  #reservations_list {
    grid-area: list;
    height: 100%;
    background: $lk_grey_2;

    .reservationItem {
      //@extend .w-100;
      //@extend .py-3;
      //@extend .px-2;
      min-height: 140px;
      margin-right: -4px;
      position: relative;
      background: $lk_grey_1;

      &:hover {
        background: $lk_white;
      }

      .reservationStatus {
        position: absolute;
        content: "";
        width: 4px;
        height: 100%;
        display: block;
        top: 0;
        right: 0;
        background: #2499A9;
      }

      .status_bg_1, .status_bg_2, .status_bg_5, .status_bg_6 {
        background-color: $lk_yellow;
      }

      .status_bg_3, .status_bg_7, .status_bg_9 {
        background-color: $lk_green;
      }

      .status_bg_4 {
        background-color: $lk_blue;
      }

      .status_bg_10 {
        background-color: $lk_red;
      }

      .text-1, .text-2, .text-5, .text-6 {
        color: $lk_yellow;
      }

      .text-3, .text-7, .text-9 {
        color: $lk_green;
      }

      .text-4 {
        color: $lk_blue;
      }

      .text-10 {
        color: $lk_red;
      }
    }
  }
}

// @media only screen and (min-width: 769px) {
@include media-breakpoint-up(md) {
  .mx-datepicker-header {
    .row {
      max-width: 100%;
    }
  }
  .mx-calendar {
    width: 248px;
  }

  .closeDetails {
    display: none;
  }

  .hasRes {
    display: block;
  }

  #reservation_overview {
    display: grid;
    grid-template-columns: 350px 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "list details";
    border-left: 1px solid $lk_grey_3;

    .overview_wrapper {
      .overview_header {
        margin-bottom: 10px;
      }
    }

    #reservations_list {
      overflow: scroll;
      padding-top: 10px;
    }

    #reservation_details {
      grid-area: details;
      overflow: scroll;

    }
  }
}


</style>
