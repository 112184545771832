import Vue from 'vue';

Vue.filter('money', function (value, currency) {
    if (parseInt(currency) === 1) {
      return (value / 100).toFixed(2).replace(".", ",");
    }

    return (value / 100).toFixed(2);
  });

Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
  },
)
