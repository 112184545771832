<template>
  <div>
    <b-container fluid class="mt-2">
      <b-row>
        <b-col cols="12" xl="10">
          <!-- mand -->
          <template>
            <b-row
                class="mb-2"
            >
              <b-col cols="12" class="">
                <b-card :header="$t('alg.nieuwereservering')"
                        class="border-0 shadow-sm"
                >
                  <b-row class="pb-3 border-bottom">
                    <b-col cols="12">
                      <b-button
                          class="tapbutton"
                          block
                          :disabled="chid === null || chid === 0 || this.orderlines.length < 1"
                          :variant="chid === null || chid === 0 || this.orderlines.length < 1 ? 'warning' : 'success'"
                          @click="saveRes()"
                      ><span class="text-dark">{{ makeResText }}</span>
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row v-if="orderlines.length === 0" class="mt-3">
                    <b-col cols="12" class="text-center font-italic">
                      {{ $t('alg.geengegevens') }}
                    </b-col>
                  </b-row>
                  <b-row
                      v-for="(orderline, index) in orderlines"
                      v-bind:key="index"
                      class="border-bottom py-2"
                  >
                    <b-col cols="2">
                      <div class="text-dark bold">{{ orderline.data.a.weergavenaam }}</div>
                      <div class="text-black-50">{{ orderline.date | moment('DD-MM-YYYY') }}</div>
                    </b-col>
                    <b-col cols="2">
                      <div class="text-dark">{{ orderline.data.s.Blockname }}</div>
                      <div class="text-black-50">{{ orderline.starttime.substring(0, 5) }} - {{ orderline.endtime.substring(0, 5) }}</div>
                    </b-col>
                    <b-col cols="2">
                      <div><span v-html="orderline.data.t.symbol"></span><span class="text-dark">{{ orderline.data.t.price | money }} </span></div>
                      <div class="text-black-50">{{ orderline.data.t.description }}</div>
                    </b-col>
                    <b-col cols="2">
                      <span>{{ orderline.pax }} x</span>
                    </b-col>
                    <b-col cols="2">
                      <span v-html="orderline.data.t.symbol"></span><span>{{ (orderline.data.t.price * orderline.pax) | money }}</span>
                    </b-col>
                    <b-col cols="2" class="text-center">
                      <b-button
                          variant="danger"
                          size="sm"
                          v-on:click="removeOrderLine(index)"
                      >
                        <b-icon icon="x"></b-icon>
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row v-if="this.orderlines.length > 0">
                    <b-col cols="12" class="mt-3">
                      <b-button
                          v-if="!newline"
                          class="tapbutton"
                          block
                          @click="newline = true"
                      ><span>{{ $t('reserveringnew.nogeen') }}</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </template>

          <!-- vandaag morgen anders -->
          <template v-if="orderlines.length === 0 && !chosenDate"

          >
            <b-card :header="$t('alg.datum')"
                    class="border-0 shadow-sm"
            >
              <b-row class="mb-2">
                <b-col cols="12" md="4">
                  <b-button
                      block
                      class="tile"
                      @click="openNewLine('today')"
                  >
                    {{$t('reslijst.datumpickervandaag')}}
                  </b-button>
                </b-col>
                <b-col cols="12" md="4">
                  <b-button
                      block
                      class="tile"
                      @click="openNewLine('tommorow')"
                  >
                    {{$t('reslijst.datumpickermorgen')}}
                  </b-button>
                </b-col>
                <b-col cols="12" md="4">
                  <b-button
                      block
                      class="tile"
                      @click="openNewLine('diff')"
                  >
                    {{$t('reslijst.datumpickernieuw')}}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>

          </template>

          <!-- addResLines -->
          <template
              v-if="newline"
          >
            <AddReservationLines
                v-on:newRowsAdded="newRowsAdded"
                :propdate="chosenDate"
            ></AddReservationLines>
          </template>
          <!-- klant -->
          <template
              v-if="orderlines.length > 0"
          >
            <b-row>
              <b-col cols="12">
                <template v-if="chid === null">
                  <b-card :header="$t('klant.klantdetails')"
                          class="border-0 shadow-sm"
                  >
                    <div v-if="orderlines.length ===0" class="font-italic mb-2">
                      {{ $t('alg.maakselectie') }}
                    </div>

                    <template v-if="orderlines.length > 0">
                      <b-row>
                        <b-col cols="12" lg="8">
                          <div class="">
                            <b-form>
                              <b-input-group
                              >
                                <b-input-group-prepend>
                                  <b-input-group-text>
                                    {{ $t('klant.achternaam') }}
                                  </b-input-group-text>
                                </b-input-group-prepend>
                                <b-form-input v-model="searchstring" @input="customerSearch"></b-form-input>
                              </b-input-group>
                            </b-form>
                          </div>
                          <div class="w-100 font-italic text-center" v-if="searchresults === null">
                            {{ $t('alg.geengegevens') }}
                          </div>
                          <b-table
                              v-if="searchresults !== null"
                              sticky-header="200px"
                              selectable
                              select-mode="single"
                              primary-key="CustomerHID"
                              @row-selected="customerChosen"
                              :fields="[
                                  {
                                    key: 'klantnaam',
                                    label: $t('klant.naam'),
                                    sortable: true
                                  },
                                  {
                                    key: 'emailadres',
                                    label: $t('klant.emailadres'),
                                    sortable: true
                                  },
                                  {
                                    key: 'telefoon_mobiel',
                                    label: $t('klant.telefoonnummer'),
                                    sortable: true,
                                  }
                          ]"
                              :items="searchresults"
                          >

                          </b-table>
                        </b-col>
                        <b-col cols="12" lg="" class="mt-2 mt-md-0">
                          <b-button-group class="w-100">
                            <b-button
                                :disabled="searchstring.length < 2"
                                @click="customerNew()"
                            >{{ $t('klant.maaknieuweklant') }}
                            </b-button>
                            <b-button
                              variant="primary"
                              @click="customerPassing()"
                            >{{ $t('klant.passant') }}
                            </b-button>
                          </b-button-group>
                        </b-col>
                      </b-row>
                    </template>
                  </b-card>
                </template>
                <Customer
                    v-if="chid !== null || lastname !== null"
                    :chid="chid"
                    :lastname="lastname"
                    lastname-in-header="true"
                    v-on:customerSelected="customerSelected"
                ></Customer>
              </b-col>
            </b-row>
          </template>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LayoutDefault from '../layouts/LayoutDefault.vue';

import CustomerService from '../services/customer.service';
import ReservationsService from '../services/reservations.service';

import AddReservationLines from '@/components/AddReservationLines'
import Customer from "@/components/Customer";

export default {
  name: 'ReservationNew',
  data() {
    return {
      currentShop: this.$store.getters["bus/currentShop"],
      orderlines: [],
      chosenDate: undefined,
      total: 0,
      valutasign: null,
      chid: null,
      lastname: null,
      search: true,
      searchstring: '',
      searchresults: null,
      newline: false
    }
  },
  components: {
    Customer,
    AddReservationLines
  },
  computed: {
    makeResText() {
      let t = '';
      if (this.orderlines.length < 1) {
        t = this.$t('reserveringnew.step1')
      } else if (this.chid === null || this.chid === 0) {
        t = this.$t('reserveringnew.step2')
      } else {
        t = this.$t('alg.maakreservering')
      }
      return t
    }
  },
  methods: {
    newRowsAdded(data) {
      let subtotal = this.total
      for (const row in data) {
        this.orderlines.push(data[row])

        subtotal += (data[row].data.t.price * data[row].pax)
        this.valutasign = data[row].data.t.symbol
      }
      this.total = subtotal
      this.newline = false
    },
    openNewLine(d) {
      const date = new this.$moment()

      if (d === "today") {
        this.chosenDate = date.format("YYYY-MM-DD")
      } else if (d === "tommorow") {
        this.chosenDate = date.add(1, 'days').format("YYYY-MM-DD")
      } else {
        this.chosenDate = null
      }
      this.newline = true
    },
    customerSelected(chid) {
      this.chid = chid
    },
    customerNew(){
      this.lastname = this.searchstring
    },
    customerPassing(){
      this.chid = "a64eba55b6bbd07369daf8ae62731a53dcd042d1d023f1e65f07e080a0efac90"
    },
    customerChosen(data) {
      if (data[0] && data[0].CustomerHID) {
        this.customerSelected(data[0].CustomerHID)
      } else {
        this.toast('err', 'Invalid Customer!')
        this.searchstring = ''
        this.searchresults = null
      }
    },
    async customerSearch() {
      if(this.searchstring.length < 2){
        return
      }
      try {
        const results = await CustomerService.findCustomer(this.$store.getters["bus/currentShop"].hashcode, this.searchstring)
        this.searchresults = results.searchresult
      } catch (e) {
        this.searchresults = null
      }

    },
    async saveRes() {
      // customer check
      if (this.chid === null || this.chid === 0) {
        return
      }

      let reservationhid = null
      // create empty res
      try {
        const resdata = await ReservationsService.create(this.currentShop.hashcode, this.chid)
        reservationhid = resdata.reservationhid
      } catch (err) {
        this.toast('err', err)
      }

      // save orderlines
      try {
        await ReservationsService.setdetails(this.currentShop.hashcode, reservationhid, this.orderlines)
        await this.$router.push('/reservations')
        await this.$store.dispatch("bus/changeRes", reservationhid)
      } catch (err) {
        this.toast('err', err)
      }
    },
    removeOrderLine(orderlineId) {
      this.orderlines.splice(orderlineId, 1)
      if(this.orderlines.length === 0){
        this.init()
      }
    },
    init() {
      this.currentShop = this.$store.getters["bus/currentShop"]
      this.chosenDate = undefined
      this.orderlines = []
      this.total = 0
      this.valutasign = null
      this.chid = null
      this.$emit('clearAddReservationSelection')
    }
  },
  created() {
    this.$emit(`update:layout`, LayoutDefault);
    this.unwatchShop = this.$store.watch(
        (state, getters) => getters["bus/currentShop"],
        () => {
          this.init()
        },
    );
    if(this.$store.getters["bus/currentShop"] !== null) {
      this.init()
    }
  },
  beforeDestroy() {
    this.unwatchShop();
  },
}
</script>

<style scoped lang="scss">

@include media-breakpoint-up(md) {
  .tile {
    height: $tile-height-lg;
    margin-bottom: 0;
  }
}
</style>
