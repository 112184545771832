<template>
  <b-container fluid class="mt-2">
    <b-row cols="12">
      <b-col>
        <b-card :header="'shortcode'"
                class="border-0 shadow-sm p-2"
        >
          {{message}}
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ReservationsService from '../services/reservations.service';

export default {
  name: "ShortCode",
  methods: {},
  data() {
    return {
      message: this.$route.params.shortcode,
    };
  },
  created() {
    if(this.$route.params.shortcode && this.$route.params.shortcode !== null){
      ReservationsService.fromShortCode(this.$route.params.shortcode)
        .then((data)=>{
          if(data.reshash && data.reshash !== null){
            this.$router.push(`/reservations`)
                .then(()=>{this.$router.push(`/reservations/${data.reshash}`)})
                .then(()=>{this.$store.dispatch("bus/changeRes", data.reshash)})

          }else{
            this.message = "ShortCode not found..."
          }
        })
        .catch(()=>{
         this.message = "ShortCode not found..."
        })

    }else{
      this.message = "ShortCode not set or empty"
    }
  }
}

</script>

<style scoped>

</style>
