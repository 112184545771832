import axios from 'axios';
import store from "@/store";
import { router } from '@/router';

axios.interceptors.request.use(
  (req) => {
    if(req.method === 'post'){
      req.data.environment = 'production'
      req.data.fromfd = true
    }
    if (store && store.getters["user/loggedIn"]) {
      req.headers.Authorization = 'Token ' + store.getters["user/jwt"]
    }
    return req;
  }
);

// doing something with the response
axios.interceptors.response.use(
  (response) => {
    // all 2xx/3xx responses will end here
    return response;
  },
  (error) => {
    // all 4xx/5xx responses will end here
    if(error.response.status === 401){
      if(router.currentRoute.path !== '/login'){
          store.dispatch("user/logout");
      }else{
        return Promise.reject('Unauthorized');
      }
    }else{
      return Promise.reject('unknown error');
    }
  }
);

export default axios;
