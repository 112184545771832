import axios from '@/plugins/axios-ext'
import promiseHandlers from "@/services/promise-handlers";

class AddOnsService {
  getIntergrations(shophid){
    return new Promise((resolve, reject) => {
      axios.post('https://api.leisureking.eu/v3l/addons/getIntergrations', {shophid:shophid})
        .then(res => promiseHandlers.pThen(res, resolve, reject))
        .catch(res => promiseHandlers.pCatch(res, resolve, reject))
    })
  }
}

export default new AddOnsService();


