<template>
  <div class="lockscreen">
    <b-card
        class="mx-auto lock-card col-12 col-sm-8 col-md-6 col-xl-3 p-0"
        header-tag="header"
        footer-tag="footer"
    >
      <template #header>
        <h1 class="text-center">{{ $t("lock.activatelk") }}</h1>
      </template>
      <b-card-body class="d-flex flex-column align-items-center">
        <div class="row" style="color: red" v-if="!hasLockSetting">
          {{ $t("lock.notset") }}
        </div>
        <template v-if="!loading">
          <template v-if="lockType() === 'pincode'">
            <div class="row justify-content-around w-100">
              <div
                  v-for="index in pinLength"
                  :key="index"
                  :id="index"
                  class="pin-field d-flex justify-content-center align-items-end"
                  :class="{ blinking: focusedPinFieldIndex === index - 1 }"
              >
                <span class="pin-field-value">
                  {{ pincode[index - 1] == null ? "-" : "*" }}
                </span>
              </div>
              <input
                  type="text"
                  v-on:input="pinInputHandler"
                  class="position-absolute opacity-0 pin-input w-100 h-25"
                  id="unlock-code-input" autocomplete="off"
              />
            </div>
          </template>
          <template v-else-if="lockType() === 'chip'">
            <form
                @submit.prevent="submitChip"
                class="position-absolute opacity-0 chip-input"
            >
              <input type="text" id="unlock-code-input" autocomplete="off"/>
            </form>
            <b-icon class="scan-icon" icon="upc-scan"></b-icon>
            <span class="mt-1 await-scan-message">{{
                $t("lock.awaitingscan")
              }}</span>
            <button class="btn btn-info mx-auto mt-4" @click="clickForcePin()">
              {{ $t("lock.unlockwithpin") }}
            </button>
          </template>
          <div class="row mt-4 w-100" v-show="message.length > 0">
            <div class="alert alert-danger mb-0 w-100 text-center">
              {{ message }}
            </div>
          </div>
        </template>
        <div class="loader" v-else>
          <b-icon scale="3" icon="hourglass-split"></b-icon>
        </div>
      </b-card-body>
      <template #footer>
        <div class="signout-container row mt-3">
          <button class="btn btn-secondary mx-auto" @click="signout">
            {{ $t("nav.logoff") }}
          </button>
        </div>
      </template>
    </b-card>
    <div class="container-fluid">
      <div class="row">
        <div class="col-auto mx-auto my-3">
          <a href="https://docs.google.com/document/d/1LSLKTjcYcQBC3ZKR-fFXOb0NFvt6zzlCxka9NRPZEL0/edit#heading=h.i5cej9rejkut" target="_blank" class="btn btn-outline-secondary">
            {{ $t("nav.lockscreenexplanation") }}
          </a>
        </div>
      </div>
    </div>
    <div class="container-fluid" v-show="datecheck()">
      <div class="row">
        <div class="col-12 text-center">
          <a href="https://www.leisureking.nl/nl/ikbenerbij" title="LeisureKing Inspiratiedag 18 oktober 2022" target="_blank">
            <img src="https://oms.leisureking.eu/images/e-mail-inspiratiedag.png" alt="LeisureKing Inspiratiedag 18 oktober 2022" style="max-width:100%"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutLogin from "@/layouts/LayoutLogin";
import store from "@/store/";
import userService from "@/services/user.service";

export default {
  name: "Lock",
  data() {
    return {
      message: "",
      pincode: "",
      forcePin: false,
      pinLength: 4,
      focusedPinFieldIndex: 0,
      loading: false,
    };
  },
  computed: {
    hasLockSetting() {
      if (
          this.$store.getters["user/currentUser"].preferences.unlockChip === 0 &&
          this.$store.getters["user/currentUser"].preferences.unlockCode === 0
      ) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    lockType() {
      if (this.forcePin) {
        return 'pincode'
      } else {
        return this.$store.getters["user/currentUser"].preferences.unlockChip > 0 ? 'chip' : 'pincode'
      }
    },
    clickForcePin() {
      this.forcePin = true
      this.message = ''
      this.focusInput()
    },
    pinInputHandler(e) {
      const input = e.target.value
      e.target.value = ""
      if (
          input.match(/^\d{1}$/) &&
          this.focusedPinFieldIndex < this.pinLength
      ) {
        this.pincode += input;
        this.focusedPinFieldIndex++;
      } else if (e.key === "Backspace") {
        this.pincode = this.pincode.slice(0, -1);
        this.focusedPinFieldIndex--;
      }
      if (this.focusedPinFieldIndex === this.pinLength) {
        this.submitUnlockCode(this.pincode);
        this.pincode = "";
        this.focusedPinFieldIndex = 0;
      }

    },
    submitChip() {
      const chipInput = document.getElementById("unlock-code-input");
      this.submitUnlockCode(chipInput.value);
    },
    submitUnlockCode(code) {
      this.loading = true;
      userService.checkUnlockCode(code)
          .then((res) => {
            if (res.isValid) {
              this.message = "";
              this.unlock();
            } else {
              this.message = this.$t("lock.codeinvalid");
            }
            this.loading = false;
          })
        .catch(()=>{
          this.signout();
        })
    },
    focusInput() {
      if (window.focusInterval != null) {
        clearInterval(window.focusInterval);
      }

      window.focusInterval = setInterval(() => {
        const input = document.getElementById("unlock-code-input");
        if (input != null && document.activeElement !== input) {
          input.focus();
        }
      }, 500);
    },
    signout() {
      this.$store.dispatch("user/logout");
    },
    unlock() {
      this.$store.commit("user/unlock");
      this.$router.go(-1);
      this.$store.commit("misc/updateLastInteractionDT");
    },
    datecheck() {
      let date = new this.$moment("2022-10-17")
      let now = new this.$moment()
      if(now < date){
        return true
      }else
        return false
    }
  },
  mounted() {
    this.pinLength = this.$store.getters["user/currentUser"].preferences.unlockCode
    this.focusInput()
  },
  destroyed() {
    clearInterval(window.focusInterval);
  },
  created() {
    this.$emit(`update:layout`, LayoutLogin);
    document.title = "Lock - LeisureKing Front Desk";
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.user.locked) {
      next(false);
    } else {
      next();
    }
  },
};
</script>

<style scoped lang="scss">
.lock-card {
  h1 {
    font-weight: 600;
  }

  .pin-field {
    display: inline-block;
    width: 1.5em;
    height: 2.25em;
    font-size: 2em;
    border: 1px solid gray;
    border-radius: 4px;

    &.blinking .pin-field-value {
      @keyframes blinking {
        from {
          opacity: 1;
        }
        to {
          opacity: 0.1;
        }
      }
      animation: blinking 0.5s infinite linear alternate;
    }

    .pin-field-value {
      color: $primary;
      text-align: center;
    }
  }

  .opacity-0 {
    opacity: 0;
  }

  .scan-icon {
    color: $primary;
    width: 3.5rem;
    height: 3.5rem;
  }

  .await-scan-message {
    position: relative;
    font-weight: bolder;

    &::after {
      content: "";
      position: absolute;
      left: 100%;
      animation: ellipsis-animated 10s infinite;
    }

    @keyframes ellipsis-animated {
      0%,
      100% {
        content: ".";
      }
      33% {
        content: "..";
      }
      66% {
        content: "...";
      }
    }
  }
}
</style>
