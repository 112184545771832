<template>
  <div>
    <b-container fluid class="mt-2">
      <b-row>
        <b-col cols="12" xl="10">
          <!-- mand -->
          <template>
            <b-row
                class="mb-2"
            >
              <b-col cols="12" class="">
                <b-card :header="$t('alg.selectie')"
                        class="border-0 shadow-sm"
                >
                  <b-row class="pb-3 border-bottom">
                    <b-col cols="12">
                      <b-button
                          v-if="!reservation"
                          class="tapbutton"
                          block
                          :disabled="chid === null || chid === 0 || this.orderlines.length < 1"
                          :variant="chid === null || chid === 0 || this.orderlines.length < 1 ? 'warning' : 'success'"
                          @click="saveRes()"
                      ><span class="h5 font-weight-bolder text-white">{{ makeResText }}</span>
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row v-if="orderlines.length === 0" class="mt-3">
                    <b-col cols="12" class="text-center font-italic">
                      {{ $t('alg.geengegevens') }}
                    </b-col>
                  </b-row>
                  <b-row
                      v-for="(orderline, index) in orderlines"
                      v-bind:key="index"
                      class="border-bottom py-2"
                  >
                    <b-col cols="4">
                      <div class="text-dark bold">{{ orderline.data.a.weergavenaam }}</div>
                      <div class="text-black-50">{{ orderline.date | moment('DD-MM-YYYY') }}</div>
                    </b-col>
                    <b-col cols="2">
                      <div><span v-html="orderline.data.t.symbol"></span><span class="text-dark">{{ orderline.data.t.price | money }} </span></div>
<!--                      <div class="text-black-50">{{ orderline.data.t.description }}</div>-->
                    </b-col>
                    <b-col cols="2">
                      <span>{{ orderline.pax }} x</span>
                    </b-col>
                    <b-col cols="2">
                      <span v-html="orderline.data.t.symbol"></span><span>{{ (orderline.data.t.price * orderline.pax) | money }}</span>
                    </b-col>
                    <b-col cols="2" class="text-center">
                      <b-button
                          variant="danger"
                          size="sm"
                          v-on:click="removeOrderLine(index)"
                      >
                        <b-icon icon="x"></b-icon>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </template>

          <!-- addResLines -->
          <template
              v-if="!reservation"
          >
            <AddReservationLines
                v-on:newRowsAdded="newRowsAdded"
                :propdate="chosenDate"
                fastcashier="true"
            ></AddReservationLines>
          </template>
          <template
              v-if="reservation"
          >
            <b-card :header="$t('reslijst.betalen')"
                    class="border-0 shadow-sm"
            >
              <ReservationPayments
                  :reservation="this.reservation"
                  open-payment="true"
                  @reservationsOverviewRefresh="refreshReservation()"
              >
              </ReservationPayments>
            </b-card>
          </template>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LayoutDefault from '../layouts/LayoutDefault.vue';

import ReservationsService from '../services/reservations.service';
import AddReservationLines from '@/components/AddReservationLines'
import ReservationPayments from "@/components/ReservationPayments";

export default {
  name: 'FastCashier',
  data() {
    return {
      currentShop: this.$store.getters["bus/currentShop"],
      orderlines: [],
      chosenDate: null,
      total: 0,
      valutasign: null,
      chid : null,
      lastname: null,
      search: true,
      searchstring: '',
      searchresults: null,
      reservation: null,
      reservationhid: null
    }
  },
  components: {
    AddReservationLines,
    ReservationPayments
  },
  computed: {
    makeResText() {
      let t = '';
      if (this.orderlines.length < 1) {
        t = this.$t('reserveringnew.step1')
      } else if (this.chid === null || this.chid === 0) {
        t = this.$t('reserveringnew.step2')
      } else {
        t = this.$t('alg.naarafrekenen')
      }
      return t
    }
  },
  methods: {
    newRowsAdded(data) {
      let subtotal = this.total
      for (const row in data) {
        let updated = false
        for (const orderLine in this.orderlines) {
          if(
              this.orderlines[orderLine].date === data[row].date &&
              this.orderlines[orderLine].id_assortiment === data[row].id_assortiment &&
              this.orderlines[orderLine].id_block === data[row].id_block &&
              this.orderlines[orderLine].id_tarif === data[row].id_tarif
          ){
            updated = true
            this.orderlines[orderLine].pax += 1
          }
        }
        if(!updated){
          this.orderlines.push(data[row])
        }
        subtotal += (data[row].data.t.price * data[row].pax)
        this.valutasign = data[row].data.t.symbol
      }
      this.total = subtotal
    },
    async refreshReservation(){
      this.reservation = await ReservationsService.get(this.reservationhid)
      if(this.reservation.value.amount === this.reservation.payed.amount){
        setTimeout(()=>{this.init()}, 500)
      }
    },
    async saveRes() {
      // customer check
      if (this.chid === null || this.chid === 0) {
        return
      }

      // create empty res
      try {
        const resdata = await ReservationsService.create(this.currentShop.hashcode, this.chid)
        this.reservationhid = resdata.reservationhid
      } catch (err) {
        this.toast('err', err)
      }

      // save orderlines
      try {
        await ReservationsService.setdetails(this.currentShop.hashcode, this.reservationhid, this.orderlines)
        await this.refreshReservation()
      } catch (err) {
        this.toast('err', err)
      }
    },
    removeOrderLine(orderlineId) {
      this.orderlines.splice(orderlineId, 1)
      if(this.orderlines.length === 0){
        this.init()
      }
    },
    init() {
      this.currentShop = this.$store.getters["bus/currentShop"]
      this.chosenDate = new this.$moment().format("YYYY-MM-DD")
      this.orderlines = []
      this.total = 0
      this.valutasign = null
      this.chid = "a64eba55b6bbd07369daf8ae62731a53dcd042d1d023f1e65f07e080a0efac90"
      this.reservation = null
      this.reservationhid = null
      this.$emit('clearAddReservationSelection')
    }
  },
  created() {
    this.$emit(`update:layout`, LayoutDefault);

    this.unwatchShop = this.$store.watch(
        (state, getters) => getters["bus/currentShop"],
        () => {
          this.init()
        },
    );
    if(this.$store.getters["bus/currentShop"] !== null) {
      this.init()
    }
  },
  beforeDestroy() {
    this.unwatchShop();
  },
}
</script>

<style scoped lang="scss">

@include media-breakpoint-up(md) {
  .tile {
    height: $tile-height-lg;
    margin-bottom: 0;
  }
}
</style>
