<template>
  <b-container fluid class="mt-2">
    <b-row cols="12">
      <b-col>
        <b-card
            class="border-0 shadow-sm"
        >
          <template v-slot:header>
            <div class="d-flex justify-content-between">
              <div>{{ $t('materiaal.materiaal') }}</div>
              <div>
                <div v-if="currentResHID() === null">
                  <b-button size="sm" variant="outline-warning" @click="$router.go(-1)">
                  {{ $t("alg.sluiten") }}
                  </b-button>
                </div>
              </div>
            </div>
          </template>
          <MaterialDetails :material-id="$route.params.id"
                           v-on:navigateToRes="$router.push('/reservations')"></MaterialDetails>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import MaterialDetails from "@/components/MaterialDetails"
import LayoutDefault from "@/layouts/LayoutDefault";

export default {
  name: "Material",
  components: {
    MaterialDetails
  },
  methods: {
    currentResHID() {
      return this.$store.getters['bus/currentResHID']
    }
  },
  created() {
    this.$emit(`update:layout`, LayoutDefault);
  },
}
</script>

<style scoped>

</style>
