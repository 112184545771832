class promiseHandlers {
  static pThen(res, resolve, reject) {
    if (res.status === 200 || res.status === 404) {
      if (res.data.status === 'OK' || res.data.status === 'NOTICE' || res.data.status === 'NO_DATA') {
        resolve(res.data.data)
      } else {
        reject(res.data.errormessage)
      }
    } else {
      reject('API returned faultcode: ' + res.status.toString())
    }
  }
  static pCatch() {
    //intercepted door axios-ext
  }
}

export default promiseHandlers
