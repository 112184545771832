import axios from '@/plugins/axios-ext'
import promiseHandlers from "@/services/promise-handlers";

class CustomerService {
    getCustomer(shophid, customerhid) {
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v3l/customers/get', {shophid: shophid, customerhid: customerhid})
                .then(res => promiseHandlers.pThen(res, resolve, reject))
                .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }

    setCustomer(shophid, customerData) {
        return new Promise((resolve, reject) => {
            const data = {
                shophid: shophid
            }

            if (customerData.isnew) {
                data.lastname = customerData.lastname.value
                data.isnew = true
            } else {
                for (const record in customerData) {
                    if (record === "extra1") {
                        let extraData = []

                        extraData.push({[customerData.extra1.name]: customerData.extra1.value})
                        if (customerData.extra2) {
                            extraData.push({[customerData.extra2.name]: customerData.extra2.value})
                        }
                        if (customerData.extra3) {
                            extraData.push({[customerData.extra3.name]: customerData.extra3.value})
                        }

                        data[record] = extraData
                    } else {
                        if (record === "extra2") continue
                        if (record === "extra3") continue

                        data[record.toLowerCase()] = customerData[record].value
                    }
                }
            }

            axios.post('https://api.leisureking.eu/v3l/customers/set', data)
                .then(res => promiseHandlers.pThen(res, resolve, reject))
                .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }

    findCustomer(shophid, searchstring) {
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v3l/customers/find', {shophid: shophid, searchstring: searchstring})
                .then(res => promiseHandlers.pThen(res, resolve, reject))
                .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }
}

export default new CustomerService();
