<template>
  <b-container fluid class="mt-2" :class="this.$screen.lg ? '' : 'pl-0 pr-0'">
    <b-card v-if="currentRes === null" header-tag="header" header-class="" class="p-0 border-0">
      <template #header>
        <div class="d-flex flex-wrap justify-content-between">
          Entry Scanner
        </div>
      </template>
      <b-card-body class="p-0">
        <QRScanner :success-function="handleScan"></QRScanner>
      </b-card-body>
    </b-card>
    <b-card v-if="currentRes !== null" header-class="" class="p-0 mb-3 border-0 shadow-sm">
      <b-overlay :variant="overlayVariant" opacity="1" :show="showOverlay">
      <b-card-body class="p-0">
        <b-row>
          <b-col sm="12" md="6" lg="4" class="mb-3">
            <h5>{{currentRes.customerdata.fullsaluation}}</h5>
            <div class="d-flex justify-content-start">
              <div class="detail-label">
                {{ $t('alg.reservering') }}
              </div>
              <div class="detail-data">
                {{currentRes.internalkey}}
              </div>
            </div>
            <div class="d-flex justify-content-start">
              <div class="detail-label">
                {{ $t('alg.datum') }}
              </div>
              <div class="detail-data">
                {{currentRes.dt_start  | moment('DD-MM-YYYY') }}
              </div>
            </div>
            <div class="d-flex justify-content-start">
              <div class="detail-label">
                {{ $t('betalingen.bedrag') }}
              </div>
              <div class="detail-data">
                <span v-html="currentRes.value.currency_sign"></span> {{(currentRes.value.amount / 100).toFixed(2)}}
              </div>
            </div>
            <div class="d-flex justify-content-start">
              <div class="detail-label">
                {{ $t('betalingen.betaald') }}
              </div>
              <div class="detail-data" :class="currentRes.value.amount - currentRes.payed.amount === 0 ? 'text-success' : 'bg-danger text-light'">
                <span v-html="currentRes.payed.currency_sign"></span> {{(currentRes.payed.amount / 100).toFixed(2)}}
              </div>
            </div>
            <div class="d-flex justify-content-start">
              <div class="detail-label">
                {{ $t('status.status') }}
              </div>
              <div class="detail-data" :class="currentRes.booking_status.status_id === 3 ? 'text-success' : 'bg-danger text-light'">
                {{currentRes.booking_status.content_value[currentUser.language] }}
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6" lg="4" class="mb-3">
            <h5>{{ $t('alg.reserveringsdetails') }}</h5>
            <div v-for="rd in this.currentRes.mainReservationData" v-bind:key="rd.id_reservering_details" class="d-flex justify-content-start align-items-center">
              <template v-if="rd.assortiment.weergavenaam !== 'Servicekosten'">
                <div class="pr-1 small">{{rd.startdatum | moment('DD-MM')}} {{rd.starttijd.substring(0, 5)}}</div>
                <div class="pr-1"></div>
                <div class="pr-1">
                  {{rd.aantal_gereserveerd}} x {{rd.assortiment.weergavenaam}}
                  <b-badge v-if="rd.tarifData" variant="primary">{{rd.tarifData.description}}</b-badge>
                </div>
              </template>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
      <div :class="this.$screen.lg || this.$screen.md ? '' : 'fixed-bottom'">
        <b-button-group class="w-100" size="lg" style="height: 150px;">
          <b-button squared class="w-50 border border-dark" variant="warning" @click.prevent="incorrect">
            <div class="d-flex flex-column justify-content-start">
              <div class="w-100" style="font-size: 60px;">
                <b-icon icon="exclamation-triangle"></b-icon>
              </div>
              <div class="w-100">
                <small>Overslaan</small>
              </div>
            </div>
          </b-button>
          <b-button v-if="hasRights" squared class="w-50 border border-dark" variant="success" @click.prevent="correct">
            <div class="d-flex flex-column justify-content-start">
              <div class="w-100" style="font-size: 60px;">
                <b-icon icon="check2"></b-icon>
              </div>
              <div class="w-100">
                <small>{{$t('reslijst.afhandelen')}}</small>
              </div>
            </div>
          </b-button>
        </b-button-group>
      </div>
        <template v-slot:overlay>
          <div class="text-center h3"><span style="color: white !important;">{{overlayText}}</span></div>
        </template>
      </b-overlay>
    </b-card>
  </b-container>
</template>
<script>


import LayoutDefault from "@/layouts/LayoutDefault";
import QRScanner from "@/components/QRScanner";
import ReservationsService from '../services/reservations.service';
import AssortimentService from "@/services/assortiment.service";

export default {
  name: "EntryCheck",
  components: {QRScanner},
  data() {
    return {
      currentRes: null,
      showOverlay: false,
      overlayVariant: '',
      overlayText: ''
    }
  },
  methods: {
    async handleScan(resHID){
      this.currentRes =  await ReservationsService.get(resHID);
      this.currentRes.mainReservationData = await ReservationsService.getdetails(resHID);
      this.currentRes.mainReservationData.forEach((item)=> {
        AssortimentService.getTarif(item.id_assortiment, item.id_block)
            .then((res) => {
              res.forEach(i => {
                if (i.id_tarif === item.id_tarif) {
                  this.$set(item, "tarifData", i)
                }
              })
            })
      })
    },
    correct() {
      ReservationsService.setstatus(this.currentRes.hash, 7)
          .then(()=>{
            this.overlayVariant = 'success'
            this.overlayText = `${this.$t('alg.reservering')} status ${this.$t('reslijst.uitgesteld')}  `,`Status ${this.$t('alg.opgeslagen')}`
            this.showOverlay = true
            setTimeout(()=>{
              this.showOverlay = false
              this.init()
            }, 1000)
          })
          .catch(()=>{
            this.overlayVariant = 'danger'
            this.overlayText = `Error updating reservation status!`
            this.showOverlay = true
            setTimeout(()=>{
              this.showOverlay = false
              this.init()
            }, 1000)
          })
    },
    incorrect() {
      this.init()
    },
    init(){
      this.currentRes = null;
    }
  },

  computed: {
    currentUser() {
      return this.$store.getters["user/currentUser"]
    },
    currentShop() {
      return this.$store.getters["bus/currentShop"]
    },
    hasRights() {
      return this.$store.getters['user/hasRight']('reservation_crud');
    }
  },
  created() {
    this.$emit(`update:layout`, LayoutDefault);
  },
  mounted() {
  }
}

</script>

<style lang="scss" scoped>

.detail-label{
    font-size: 80%;
    line-height: 24px;
    width: 8rem;
    color: #666666;
}

.detail-data{
  text-align: start;
  width: 100%;
  font-weight:700;
  color: #666666;
}

</style>
