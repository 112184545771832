import axios from '@/plugins/axios-ext'
import promiseHandlers from "@/services/promise-handlers";
import moment from "moment";

class ReservationsService {
    find(vestiging, searchstring) {
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v3l/reservations/find', {
                shophid: vestiging, searchstring: searchstring
            })
                .then(res => promiseHandlers.pThen(res, resolve, reject))
                .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }

    list(vestiging, startdate, enddate, startfrom) {
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v3l/reservations/listing', {
                shophid: vestiging, startdate: startdate, enddate: enddate, startfrom: startfrom
            })
                .then(res => promiseHandlers.pThen(res, resolve, reject))
                .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }

    listNew(vestiging, startfrom, startTimestamp = null) {
        if (!startTimestamp) {
            startTimestamp = moment().format("YYYY-MM-DD HH:mm:ss")
        }
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v3l/reservations/listing', {
                shophid: vestiging,
                newerthen: startTimestamp,
                startfrom: startfrom
            })
                .then(res => promiseHandlers.pThen(res, resolve, reject))
                .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }

    get(resHID) {
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v3l/reservations/get', {reservationhid: resHID})
                .then(res => promiseHandlers.pThen(res, resolve, reject))
                .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }

    create(vestiging, customerhid) {
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v3l/reservations/create', {
                shophid: vestiging, customerhid: customerhid, fromfd: true
            })
                .then(res => promiseHandlers.pThen(res, resolve, reject))
                .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }

    getdetails(resHID) {
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v3l/reservations/getdetails', {reservationhid: resHID})
                .then(res => promiseHandlers.pThen(res, resolve, reject))
                .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }

    setdetails(vestiging, resHID, orderLines) {
        return axios.post('https://api.leisureking.eu/v3l/reservations/setDetails', {
            shophid: vestiging, reservationhid: resHID, orderlines: orderLines
        })
    }

    setstatus(resHID, status) {
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v3l/reservations/setstatus', {
                reservationhid: resHID, status: status
            })
                .then(res => promiseHandlers.pThen(res, resolve, reject))
                .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }

    getpayments(vestiging, resHID) {
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v3l/reservations/getPayments', {
                shophid: vestiging, reservationhid: resHID
            })
                .then(res => promiseHandlers.pThen(res, resolve, reject))
                .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }

    setPayment(vestiging, resHID, amount, idpm, id_terminal = null) {
        return axios.post('https://api.leisureking.eu/v3l/reservations/setPayment', {
            shophid: vestiging,
            reservationhid: resHID,
            amount: amount,
            id_payment_method: idpm,
            id_terminal: id_terminal
        })
    }

    getPaymentStatus(vestiging, statusHash, resHash) {
        return axios.post('https://api.leisureking.eu/v3l/reservations/getEFTStatus', {
            shophid: vestiging, statusHash: statusHash, reshid: resHash
        })
    }

    cancelPayment(vestiging, statusHash) {
        return axios.post('https://api.leisureking.eu/v3l/reservations/cancelEFTPayment', {
            shophid: vestiging, statusHash: statusHash
        })
    }

    getnotes(vestiging, resHID) {
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v3l/reservations/getNotes', {
                shophid: vestiging, reservationhid: resHID
            })
                .then(res => promiseHandlers.pThen(res, resolve, reject))
                .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }

    addnote(vestiging, resHID, note) {
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v3l/reservations/addNote', {
                shophid: vestiging, reservationhid: resHID, note: note
            })
                .then(res => promiseHandlers.pThen(res, resolve, reject))
                .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }

    getDocuments(resHID) {
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v3l/reservations/getDocuments', {reservationhid: resHID})
                .then(res => promiseHandlers.pThen(res, resolve, reject))
                .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }

    getInternalTickets(resHID) {
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v3l/reservations/getInternalTickets', {reservationhid: resHID})
              .then(res => promiseHandlers.pThen(res, resolve, reject))
              .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }

    addDiscountCode(vestiging, resHID, dccode) {
        return axios.post('https://api.leisureking.eu/v3l/reservations/addDiscountCode', {
            shophid: vestiging, reservationhid: resHID, dccode: dccode
        })
    }

    addBorg(vestiging, resHID, amount) {
        return axios.post('https://api.leisureking.eu/v3l/reservations/addBorg', {
            shophid: vestiging, reservationhid: resHID, amount: amount
        })
    }

    removeBorg(vestiging, resHID) {
        return axios.post('https://api.leisureking.eu/v3l/reservations/removeBorg', {
            shophid: vestiging, reservationhid: resHID
        })
    }

    sendReservationMail(resHID) {
        return axios.post('https://api.leisureking.eu/v3l/reservations/sendReservationMail', {reservationhid: resHID})
    }

    sendPaymentLinkMail(resHID) {
        return axios.post('https://api.leisureking.eu/v3l/reservations/sendPaymentLinkMail', {reservationhid: resHID})
    }

    sendPayedReservationMail(resHID) {
        return axios.post('https://api.leisureking.eu/v3l/reservations/sendPayedReservationMail', {reservationhid: resHID})
    }

    fromShortCode(shortcode) {
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v3l/reservations/fromShortCode', {shortcode: shortcode})
                .then(res => promiseHandlers.pThen(res, resolve, reject))
                .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }

    hasChanges(resHID, timestamp) {
        return axios.post('https://api.leisureking.eu/v3l/reservations/hasChanges', {
            reservationhid: resHID,
            timestamp,
        })
    }

}

export default new ReservationsService();
