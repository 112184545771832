import axios from '@/plugins/axios-ext'
import promiseHandlers from "@/services/promise-handlers";

class ShopsService {
  getPaymentMethods(shophid) {
    return new Promise((resolve, reject) => {
      axios.post('https://api.leisureking.eu/v3l/shops/getpaymentmethods', {shophid:shophid})
        .then(res => promiseHandlers.pThen(res, resolve, reject))
        .catch(res => promiseHandlers.pCatch(res, resolve, reject))
    })
  }
  getLogo(shophid) {
    return new Promise((resolve, reject) => {
      axios.post('https://api.leisureking.eu/v3l/shops/getlogo', {shophid:shophid})
        .then(res => promiseHandlers.pThen(res, resolve, reject))
        .catch(res => promiseHandlers.pCatch(res, resolve, reject))
    })
  }
  getOptions(shophid) {
    return new Promise((resolve, reject) => {
      axios.post('https://api.leisureking.eu/v3l/shops/getOptions', {shophid:shophid})
        .then(res => promiseHandlers.pThen(res, resolve, reject))
        .catch(res => promiseHandlers.pCatch(res, resolve, reject))
    })
  }
  getValuta(shophid){
    return new Promise((resolve, reject) => {
      axios.post('https://api.leisureking.eu/v3l/shops/getValuta', {shophid:shophid})
        .then(res => promiseHandlers.pThen(res, resolve, reject))
        .catch(res => promiseHandlers.pCatch(res, resolve, reject))
    })
  }
  getDiscountCode(shophid, dccode){
    return axios.post('https://api.leisureking.eu/v3l/shops/dcGetData', {shophid:shophid, dccode: dccode})
  }
  checkPin(shophid, pin){
    return axios.post('https://api.leisureking.eu/v3l/shops/checkpin', {shophid:shophid, pin: pin})
  }
}

export default new ShopsService();


