export default class User {
  constructor(username, email, password) {
    this.username = username
    this.email = email
    this.password = password
    this.language = 'nl'
    this.token = ''
    this.hashcode = ''
  }
}
