import Vue from 'vue';
import Vuex from 'vuex';
// import createPersistedState from 'vuex-persistedstate'
import createMultiTabState from 'vuex-multi-tab-state';

import { user } from './user.module'
import { bus } from "./bus.module";
import { misc } from "./misc.module";

Vue.use(Vuex);
const store = new Vuex.Store({
  plugins: [
    createMultiTabState({
      statesPaths: ["user","misc"],
    }),
  ],
  modules: {
    user,
    bus,
    misc
  }
});
export default store
