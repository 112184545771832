import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

const VueScrollTo = require("vue-scrollto");

import Login from "@/views/Login";
import Lock from "@/views/Lock";
import ReservationsOverview from "@/views/ReservationsOverview";
import Reservation from "@/views/ReservationNew";
import Material from "@/views/Material";
import Settings from "@/views/Settings";
import ShortCode from "@/views/ShortCode";
import FastCashier from "@/views/FastCashier";
import Version from "@/views/Version";
import EntryScan from "@/views/EntryScan";

Vue.use(Router);

export const router = new Router({
    routes: [
        {
            path: "/",
            redirect: "/reservations",
            meta: {
                isMenuItem: false,
            },
        },
        {
            path: "/login",
            component: Login,
            meta: {
                isMenuItem: false,
                requiresAuth: false,
                noLock: true,
            },
        },
        {
            path: "/lock",
            name: "lock",
            component: Lock,
            meta: {
                isMenuItem: false,
                requiresAuth: true,
                noLock: true,
            },
        },
        {
            path: "/reservationnew",
            name: "ReservationNew",
            component: Reservation,
            meta: {
                caption: "reservering",
                icon: "node-plus-fill",
                isMainMenu: true,
                requiresAuth: true,
                requiredRights: ["reservation_crud"],
                ord: 2,
            },
        },
        {
            path: "/reservations/:hid?",
            name: "ReservationsOverview",
            component: ReservationsOverview,
            meta: {
                caption: "overzicht",
                icon: "calendar-fill",
                isMainMenu: true,
                requiresAuth: true,
                ord: 3,
            },
        },
        {
            path: "/settings",
            name: "Settings",
            component: Settings,
            meta: {
                caption: "instelling",
                icon: "gear-fill",
                isMenuItem: true,
                requiresAuth: true,
                ord: 7,
            },
        },
        {
            path: "/material/:id",
            name: "Material",
            component: Material,
            meta: {
                caption: "materiaal",
                icon: "gear-fill",
                isMenuItem: false,
                requiresAuth: true,
                ord: 5,
            },
        },
        {
            path: "/shortcode/:shortcode",
            name: "Shortcode",
            component: ShortCode,
            meta: {
                caption: "shortcode",
                icon: "gear-fill",
                isMenuItem: false,
                requiresAuth: true,
                ord: 6,
            },
        },
        {
            path: "/fastcashier",
            name: "FastCashier",
            component: FastCashier,
            meta: {
                caption: "fastcashier",
                icon: "lightning-fill",
                isMenuItem: true,
                requiresAuth: true,
                requiresOption: "fastcashier",
                requiresAddon: "4EE8FE36A78A83682D882B66D8F27ECD",
                requiredRights: ["reservation_crud", "ao_fca_use"],
                ord: 5,
            },
        },
        {
            path: "/version",
            name: "version",
            component: Version,
            meta: {
                caption: "version",
                icon: "lightning-fill",
                isMenuItem: false,
                requiresAuth: true,
                ord: 8,
            },
        },
        {
            path: "/entryscan",
            name: "entryScan",
            component: EntryScan,
            meta: {
                captionText: "EntryScan",
                icon: "upc-scan",
                isMenuItem: true,
                requiresAuth: true,
                requiresAddon: "9B042718BF1E1A8D5FFC28E35D9B33DA",
                requiredRights: ["ao_escn_use"],
                ord: 9,
            },
        },
        {
            path: "*",
            redirect: "/reservations",
            name: "404",
        },
    ],
});

router.beforeEach((to, from, next) => {
    //auth check
    if (to.matched.some((route) => route.meta.requiresAuth)) {
        let loggedIn =
            store && store.getters["user/loggedIn"]
                ? store.getters["user/loggedIn"]
                : false;
        if (loggedIn) {
            const locked = store && store.state.user.locked ? store.state.user.locked : false;
            if (locked && to.path !== "/lock") {
                return next({path: "/lock"});
            }

            // Rights check
            if (to.meta.requiredRights && to.meta.requiredRights.length > 0) {
                if (!store.getters["user/hasRights"](to.meta.requiredRights)) {
                    return next(false);
                }
            }
        } else {
            store.dispatch("user/logout");
            return next(false);
        }
    }

    VueScrollTo.scrollTo("#top", 1, {offset: -70});
    next();
});
