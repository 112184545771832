<template>
  <b-container fluid class="mt-2">
    <b-card header-tag="header" header-class="" class="mb-2 border-0 shadow-sm">
      <template #header>
        <div class="d-flex flex-wrap justify-content-between">
          {{ $t('alg.version') }} {{appVersion}} <span v-if="latestVersion !== appVersion">Nieuwste versie {{latestVersion}}</span>
          <div class="d-flex justify-content-end">
            <b-button v-if="latestVersion !== appVersion" @click.prevent="updateVersion" size="sm" class="mr-3">
              <span class="text-no-break">Update</span>
            </b-button>
          </div>
        </div>
      </template>
      <div class="mb-2" v-if="latestVersion !== appVersion">
        {{$t('alg.versionupdate')}} {{ $t('alg.update')}}
      </div>
      <b-list-group>
        <b-list-group-item v-for="(data, version) in $t('version')" v-bind:key="version">
          <b>{{ version }}</b>
          <ul>
            <li v-for="change in data" v-bind:key="change">
              {{ change }}
            </li>
          </ul>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </b-container>
</template>

<script>


import LayoutDefault from "@/layouts/LayoutDefault";

export default {
  name: "Version",
  methods: {
    updateVersion(){
      this.$router.push('/login').then(()=>{
        setTimeout(()=>{
          location.reload(true);
        }, 100)
      })
    }
  },
  data() {
    return {
      appVersion: this.$appVersion
    }
  },
  created() {
    this.$emit(`update:layout`, LayoutDefault);
  },
  computed: {
    latestVersion() {
      return this.$store.getters["user/currentUser"] ? this.$store.getters["user/currentUser"].fdAppVersion : "0.0.0"
    }
  }
}

</script>

<style scoped>

</style>
