import axios from '@/plugins/axios-ext'
import promiseHandlers from "@/services/promise-handlers";

class AssortimentService {
    get() {
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v2l/assortiment/get', {})
                .then(res => promiseHandlers.pThen(res, resolve, reject))
                .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }
    getAllShops(){
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v3l/assortiment/getAllShops', {})
                .then(res => promiseHandlers.pThen(res, resolve, reject))
                .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }
    getDetails(idAssortiment){
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v2l/assortiment/getAssortimentDataByAssortimentID', {'id_assortiment': idAssortiment})
                .then(res => promiseHandlers.pThen(res, resolve, reject))
                .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }
    getSlots(idAssortiment, date){
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v3l/assortiment/getdtoptionsbyassortimentidanddate', {'id_assortiment': idAssortiment, 'date': date})
                .then(res => promiseHandlers.pThen(res, resolve, reject))
                .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }
    getTarif(idAssortiment, idSlot){
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v3l/assortiment/gettarif', {'id_assortiment': idAssortiment, 'id_block': idSlot})
                .then(res => promiseHandlers.pThen(res, resolve, reject))
                .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }
    getStockDetails(idAssortiment, idSlot, date, starttime){
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v2l/assortiment/getstockdetails', {'id_assortiment': idAssortiment, 'id_block': idSlot, 'date': date, 'starttime': starttime})
                .then(res => promiseHandlers.pThen(res, resolve, reject))
                .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }
    getStockDataByArrayAssortimentDate(arrIdAssortiment, date){
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v3l/assortiment/getStockDataByArrayAssortimentDate', {'arr_assortiment': arrIdAssortiment, 'date': date})
              .then(res => promiseHandlers.pThen(res, resolve, reject))
              .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }
    getBlockData(idAssortiment, idSlot){
        return new Promise((resolve, reject) => {
            axios.post('https://api.leisureking.eu/v3l/assortiment/getblockdata', {'id_assortiment': idAssortiment, 'id_block': idSlot})
                .then(res => promiseHandlers.pThen(res, resolve, reject))
                .catch(res => promiseHandlers.pCatch(res, resolve, reject))
        })
    }

}

export default new AssortimentService();
